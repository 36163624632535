<template>
    <FormItem v-bind="$attrs" :class="[getelPositionClass, { 'hide-form-item-label': hideFormItemel }]">
        <template slot="label">
            <slot name="label">
                <span>{{ $attrs.label }}{{ labelSuffix }}</span>
            </slot>
            <div :class="`${prefixCls}-item__label__append`" :style="getelAppendStyle">
                <slot name="label-append">
                    {{ $attrs.append }}
                </slot>
            </div>
        </template>
        <slot>
            <component
                v-if="getComponentName !== 'Text'"
                :is="useComponentName($attrs)"
                v-model="model[$attrs.prop]"
                v-bind="getComponentProps"
                @focus="focus"
                @blur="blur"
                @change="change"
                @input="input"
                @clear="clear"
                @custom-event="handleCustomEvent"
            >
                <template v-for="slotName in slotNames">
                    <template v-if="slotName === 'in-component'">
                        <slot :name="slotName"></slot>
                    </template>
                    <template v-else :slot="slotName">
                        <slot :name="slotName"></slot>
                    </template>
                </template>
            </component>
            <span v-else v-bind="getComponentProps">{{ model[$attrs.prop] || "" }}</span>
        </slot>

        <div :class="prefixCls + '-item__content-tip'">
            <slot name="content-tip">{{ contentTip }}</slot>
        </div>
    </FormItem>
</template>

<script>
import { FormItem } from "element-ui"
import { prefixCls } from "./constant"
import { useComponentName, useComponentProps } from "./hooks"
import { componentsMap } from "../../constant"
import Input from "../../Input"
import RadioGroup from "../../RadioGroup"
import CheckboxGroup from "../../CheckboxGroup"
import Select from "../../Select"
import InputNumber from "../../InputNumber"
// import ItemDivider from "./Divider.vue";
import Divider from "../../Divider";
import Upload from "../../Upload"
import Cascader from "../../Cascader"
import TreeSelect from "../../TreeSelect"
import AutoComplete from "../../Autocomplete"
import BasicSwitch from '../../Switch';
import TimePicker from '../../TimePicker';
import DatePicker from '../../DatePicker';
export default {
    name: "BasicFormItem",
    components: {
        FormItem,
        Input,
        InputNumber,
        RadioGroup,
        CheckboxGroup,
        Select,
        DatePicker,
        // ItemDivider,
        Divider,
        Upload,
        Cascader,
        TreeSelect,
        AutoComplete,
        BasicSwitch,
        TimePicker,
    },
    inject: ["getModel", "onFocus", "onBlur", "onChange", "onInput", "onClear"],
    props: {
        labelPosition: {
            type: String,
            default: "",
        },
        labelSuffix: {
            type: String,
            default: "",
        },
        contentTip: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            prefixCls,
        }
    },
    mounted() {
        // console.log('BasicFormItem', this.$slots);
    },
    computed: {
        model: {
            get() {
                return this.getModel()
            },
            set() {},
        },
        getelAppendStyle() {
            let { appendStyle: style } = this.$attrs
            return {
                textAlign: "left",
                ...(style || {}),
            }
        },
        getelPositionClass() {
            return `${this.prefixCls}-item--label-${this.labelPosition}`
        },
        hideFormItemel() {
            let { hideFormItemel } = this.$attrs
            let reg = /^0(px)?$/
            if (typeof hideFormItemel === "boolean" || reg.test(this.$attrs.labelWidth || this.$attrs["label-width"] || "")) {
                return true
            } else {
                return false
            }
        },
        prop() {
            return this.$attrs.prop
        },
        getComponentName() {
            return useComponentName(this.$attrs)
        },
        getComponentProps() {
            let mergeProps = {};
            if (this.$attrs.componentProps) {
                let { readonly, disabled } = this.$attrs.componentProps;
                if (readonly|| disabled) {
                //    this.$attrs.componentProps = { ...this.$attrs.componentProps, placeholder: "" }
                    mergeProps['placeholder'] = '';
                }   
            }
            return useComponentProps({ ...this.$attrs.componentProps, ...mergeProps }, this.$attrs)
        },
        slotNames() {
            let slotNames = Object.keys(this.$slots)
            // console.log('slotNames', slotNames);
            return slotNames.filter(slotName => {
                return !["label", "label-append"].includes(slotName)
            })
        },
    },
    watch: {
        model: {
            handler(value){
                // console.log('BasicFormItem.watch.model', value);
            },
            immediate: true
        },
        getComponentProps: {
            handler(componentProps) {
                // console.log('watch.getComponentProps', componentProps, this.getComponentName);
            },
            immediate: true,
            deep: true
        },
        'getComponentProps.options': {
            handler(value) {
                // console.log('watch.getComponentProps.options', value, this.getComponentName);
            },
            immediate: true,
        },
        slotNames: {
            handler(slotNames) {
                // console.log('watch.slotNames', this.$slots, slotNames);
            },
            immediate: true,
        },
    },
    methods: {
        useComponentName,

        focus(e) {
            this.onFocus(this.prop, e)
        },
        blur(e) {
            this.onBlur(this.prop, e)
        },
        change(...args) {
            let { prop } = this.$attrs
            args.splice(1, 0, prop)
            args.splice(2, 0, this.$attrs)
            this.onChange(...args)
        },
        input(value) {
            // console.log('BasicFormItem.input', value);
            this.onInput(value, this.prop)
        },
        clear() {
            this.onClear(this.prop)
        },
        handleCustomEvent(){
            console.log('handleCustomEvent', this.getComponentProps);
        },
    },
}
</script>
