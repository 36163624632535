import Vue from 'vue';
// electron运行环境下不支持Promise.finally 引入core-js来支持Promise.finally写法
import 'core-js/features/promise/finally';
import '@/core/Electron';



import { usePageWrapper, useForm, useTable } from 'labbol-basic-components';

// import PageWrapper from 'labbol-basic-components/src/components/PageWrapper';
// import BasicDialog from 'labbol-basic-components/src/components/BasicDialog';
// import BasicDrawer from 'labbol-basic-components/src/components/BasicDrawer';
// import BasicForm from 'labbol-basic-components/src/components/BasicForm';
// import 'labbol-basic-components/src/components/BasicForm';
// import LabbolBasicComponents from '../../../BasicComponents/src/components/index';
import LabbolBasicComponents from 'labbol-basic-components';
Vue.use(LabbolBasicComponents);

// import './global.use';
// import draggable from 'vuedraggable';
// Vue.use(draggable);

import $choicePersonnel from "@/components/ChoicePersonnel/index.js";
Vue.use($choicePersonnel);

import $verifyConfirm from "@/components/Extend/VerifyConfirm/index.js";
Vue.use($verifyConfirm);

import $choiceObject from "@/components/ChoiceObject/index.js";
Vue.use($choiceObject);

import $ChoiceTree from "@/components/ChoiceTree/index.js";
Vue.use($ChoiceTree);

import $SetFile from "@/components/SetFile/index.js";
Vue.use($SetFile);

import $FileTask from "@/components/FileTask/index.js";
Vue.use($FileTask);



import BasicDrawerUpload from '@/components/BasicDrawerUpload';
Vue.component('BasicDrawerUpload', BasicDrawerUpload);

import Preview from '@/components/Preview/index.vue';
Vue.component('Preview', Preview);

import BasicContextMenu from '@/components/BasicContextMenu';
Vue.component('BasicContextMenu', BasicContextMenu);
export {
    usePageWrapper, useForm, useTable,
    // PageWrapper, BasicDialog, BasicDrawer, BasicForm
}