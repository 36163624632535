import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import { ifrServerLogout } from "@/assets/js/Global"
import { isElectron, ipcRenderer, isDev } from "@/core/Electron"
import { setWindowToken, getWindowToken, removeWindowToken, getLocalToken, removeLocalToken } from "@/core/token"
import productCustomFormView from "product-custom-form-view"
Vue.use(productCustomFormView)

import { Index, Design } from "product-custom-form-view"
import { MessageBox } from "element-ui"
Vue.use(VueRouter)
//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush = VueRouter.prototype.push

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果成功 调用原来的push方法
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        )
    }
}

// 后台路由
const Login = () => import("../components/Login")
const Register = () => import("../components/Register")
const MainPage = () => import("../components/Layout")
const MemberDepartments = () => import("../components/OrganizationStructure/MemberDepartments") //成员与部门
const AccountManagement = () => import("../components/OrganizationStructure/AccountManagement") //账号管理
const RoleManage = () => import("../components/OrganizationStructure/RoleManage") //角色管理
const SystemAdmin = () => import("../components/OrganizationStructure/SystemAdmin") //系统管理员
const Enterprise = () => import("../components/OrganizationStructure/Enterprise") //企业信息
const Invoice = () => import("../components/OrganizationStructure/Invoice") //发票信息
const BusinessData = () => import("../components/DetectedData/BusinessData") //业务数据
const EntrustedData = () => import("../components/DetectedData/BusinessData/ChildFiles/EntrustedData") //业务数据

/** 首页路由地址 */
const HomePath = "/entrustedmanagement/home"
/** 网络无连接 路由地址 */
const OfflinePath = "/Offline"
/** 网络无连接 路由组件 */
const Offline = () => import("@/views/Offline")

const BusinessDataRoutes = [
    {
        path: "/BusinessDataSubLevel",
        name: "BusinessDataSubLevel",
        component: {
            render: e => e("router-view"),
        },
        alwaysShow: true,
        meta: {
            title: "业务数据",
            showInSideMenu: true,
        },
        children: [
            {
                path: "/BusinessData/EntrustedData",
                name: "EntrustedData",
                component: EntrustedData,
                meta: {
                    title: "委托数据",
                    icon: "entrustedDataIcon",
                    showInSideMenu: true,
                },
            },
            {
                path: "/BusinessData/SampleData",
                name: "SampleData",
                // component:EntrustedData,
                meta: {
                    title: "样品数据",
                    icon: "sampleDataIcon",
                    showInSideMenu: true,
                },
            },
            {
                path: "/BusinessData/TaskData",
                name: "TaskData",
                // component:EntrustedData,
                meta: {
                    title: "任务数据",
                    icon: "taskDataIcon",
                    showInSideMenu: true,
                },
            },
            {
                path: "/BusinessData/ReportData",
                name: "ReportData",
                // component:EntrustedData,
                meta: {
                    title: "报告数据",
                    icon: "reportDataIcon",
                    showInSideMenu: true,
                },
            },
            {
                path: "/BusinessData/TestingCosts",
                name: "TestingCosts",
                // component:EntrustedData,
                meta: {
                    title: "检测费用",
                    icon: "testingCostsIcon",
                    showInSideMenu: true,
                },
            },
        ],
    },
    {
        path: "/ControlledForm",
        name: "ControlledForm",
        alwaysShow: true,
        meta: {
            title: "自定义表单",
            showInSideMenu: true,
        },
        children: [
            {
                path: "/BusinessData/TrialCommissionAgreement",
                name: "TrialCommissionAgreement",
                meta: {
                    title: "试验委托协议",
                    icon: "trialCommissionAgreementIcon",
                    showInSideMenu: true,
                },
            },
            {
                path: "/BusinessData/SampleCirculationForm",
                name: "SampleCirculationForm",
                meta: {
                    title: "样品流转单",
                    icon: "sampleCirculationFormIcon",
                    showInSideMenu: true,
                },
            },
        ],
    },
]

const stagingRoutes = [
    {
        path: "/1",
        name: "1",
        meta: {
            moduleTitle: "工作台",
            title: "仪表盘",
            icon: "HomePage",
            showInSideMenu: true,
        },
    },
] // 工作台
const TestResourcesRoutes = [
    {
        path: "/MemberDepartments",
        name: "MemberDepartments",
        component: MemberDepartments,
        meta: {
            moduleTitle: "组织结构",
            title: "成员与部门",
            icon: "Members",
            showInSideMenu: true,
        },
    },
    {
        path: "/RoleManage",
        name: "RoleManage",
        component: RoleManage,
        meta: {
            title: "角色管理",
            icon: "testEquipment",
            showInSideMenu: true,
        },
    },
    {
        path: "/AccountManagement",
        name: "AccountManagement",
        component: AccountManagement,
        meta: {
            title: "账号管理",
            icon: "testingPersonnel",
            showInSideMenu: true,
        },
    },
    {
        path: "/SystemAdmin",
        name: "SystemAdmin",
        component: SystemAdmin,

        meta: {
            title: "系统管理员",
            icon: "testingStandards",
            showInSideMenu: true,
        },
    },
] // 检测资源
const detectedDataRoutes = [
    {
        path: "/BusinessData",
        name: "BusinessData",
        component: BusinessData,
        meta: {
            moduleTitle: "订单管理",
            title: "我的订单",
            icon: "businessData",
            showInSideMenu: true,
        },
        redirect: "/BusinessData/EntrustedData",
        children: [...BusinessDataRoutes],
    },
    {
        path: "/8",
        name: "8",
        meta: {
            title: "续费管理",
            icon: "testData",
            showInSideMenu: true,
        },
    },
    {
        path: "/9",
        name: "9",
        meta: {
            title: "我的产品",
            icon: "systemData",
            showInSideMenu: true,
        },
    },
    // {
    //     path: "/10",
    //     name: "10",
    //     meta: {
    //         title: "能力验证数据",
    //         icon: "capabilityVerificationData",
    //         showInSideMenu: true,
    //     },
    // },
    // {
    //     path: "/11",
    //     name: "11",
    //     meta: {
    //         title: "检测元数据",
    //         icon: "detectingMetadata",
    //         showInSideMenu: true,
    //     },
    // },
] // 检测数据
const reportCertificateRoutes = [
    {
        path: "/12",
        name: "12",
        meta: {
            moduleTitle: "发票管理",
            title: "开票记录",
            icon: "testReport",
            showInSideMenu: true,
        },
    },
    {
        path: "/Invoice",
        component: Invoice,
        name: "Invoice",
        meta: {
            title: "发票信息",
            icon: "LabQualification",
            showInSideMenu: true,
        },
    },
    {
        path: "/14",
        name: "14",
        meta: {
            title: "待开发票",
            icon: "authenticationCertificate",
            showInSideMenu: true,
        },
    },
] // 报告证书
const dataAnalysisRoutes = [
    {
        path: "/Enterprise",
        component: Enterprise,
        name: "Enterprise",
        meta: {
            moduleTitle: "企业设置",
            title: "企业信息",
            icon: "dataIndicators",
            showInSideMenu: true,
        },
    },
    {
        path: "/16",
        name: "16",
        meta: {
            title: "安全设置",
            icon: "aqsz",
            showInSideMenu: true,
        },
    },
    {
        path: "/17",
        name: "17",
        meta: {
            title: "管理员设置",
            icon: "gly",
            showInSideMenu: true,
        },
    },
] // 数据分析

const routes = [
    {
        path: "/LandingPage",
        name: "landing-page",
        redirect: "/entrustedmanagement",
        component: () => import("../views/LandingPage.vue"),
        beforeEnter(to, from, next) {
            store.commit("setDocumentFrontTitle")
            next()
        },
        children: [
            {
                path: "/entrustedmanagement",
                name: "entrustedmanagement",
                redirect: "/entrustedmanagement/home",
                component: () => import("../views/entrustedmanagement.vue"),
                children: [
                    // 工作台
                    {
                        path: "/entrustedmanagement/home",
                        name: "Home",
                        component: () => import("@/views/Home"),
                        meta: {
                            menuTitle: "工作台",
                            title: "首页",
                            icon: "HomePage",
                            showInSideMenu: true,
                        },
                    },

                    {
                        path: "/entrustedmanagement/mission",
                        name: "Mission",
                        component: () => import("@/views/Mission"),
                        meta: {
                            menuTitle: "任务管理",
                            title: "任务管理",
                            icon: "TaskForm",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/Task",
                        name: "Task",
                        component: () => import("@/views/TaskMisson"),
                        meta: {
                            menuTitle: "任务管理",
                            title: "任务管理",
                            icon: "TaskForm",
                            showInSideMenu: false,
                        },
                    },
                    //  报告管理
                    {
                        path: "/entrustedmanagement/reportBase",
                        name: "reportBase",
                        component: () => import("@/views/reportBase"),
                        meta: {
                            menuTitle: "报告管理",
                            title: "报告管理",
                            icon: "ReportBase",
                            showInSideMenu: true,
                        },
                    },
                    // 资源管理
                    {
                        path: "/entrustedmanagement/labQualification",
                        name: "labQualification",
                        component: () => import("@/views/LabQualification"),
                        meta: {
                            menuTitle: "资源管理",
                            title: "实验室资质",
                            icon: "LabQualification",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/object",
                        name: "object",
                        component: () => import("@/views/objectManage"),
                        meta: {
                            title: "检测对象",
                            icon: "objectManage",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/PersonelManage",
                        name: "PersonelManage",
                        component: () => import("@/views/PersonelManage"),
                        meta: {
                            title: "检测人员",
                            icon: "manage",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/standard",
                        name: "Standard",
                        component: () => import("@/views/Standard"),
                        meta: {
                            title: "标准规范",
                            icon: "Standard",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/equipmentPage",
                        name: "equipmentPage",
                        component: () => import("@/views/equipmentPage"),
                        meta: {
                            title: "仪器设备",
                            icon: "equipmentPage",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/option",
                        name: "Option",
                        component: () => import("@/views/Option"),
                        meta: {
                            title: "检测方案",
                            icon: "Option",
                            showInSideMenu: true,
                        },
                    },

                    // {
                    //     path: "/entrustedmanagement/institution",
                    //     name: "Institution",
                    //     component: () => import("@/views/Institution"),
                    //     meta: { title: "检测机构", icon: "detectionResourceService", showInSideMenu: true },
                    // },
                    // 模板管理
                    {
                        path: "/entrustedmanagement/templateLib",
                        name: "TemplateLib",
                        component: () => import("@/views/TemplateLib"),
                        meta: {
                            menuTitle: "模板管理",
                            title: "文档模板",
                            icon: "TemplateLib",
                            showInSideMenu: true,
                        },
                    },
                    // 文档结构
                    {
                        path: "/entrustedmanagement/docStructure",
                        name: "DocStructure",
                        component: () => import("@/views/TemplateLib"),
                        meta: {
                            title: "文档结构",
                            // icon: "TemplateLib",
                            icon: "docStructure",
                            type: "docStructure",
                            showInSideMenu: true,
                        },
                    },
                    // 文档模板 设计
                    {
                        path: "/entrustedmanagement/templateDesign",
                        name: "TemplateDesign",
                        component: () => import("@/views/TemplateLib/template"),
                        meta: {
                            menuTitle: "模板设计",
                            title: "模板设计",
                            icon: "TemplateLib",
                            showInSideMenu: false,
                            currentActiveMenu: "/entrustedmanagement/templateLib",
                        },
                    },
                    {
                        path: "/entrustedmanagement/dataorigin",
                        name: "DataOrigin",
                        component: () => import("@/views/DataOrigin"),
                        meta: {
                            title: "数据源",
                            icon: "DataOrigin",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/entrustedmanagement/dataset",
                        name: "DatasetNew",
                        component: () => import("@/views/Dataset"),
                        meta: {
                            title: "数据集",
                            icon: "Dataset",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/businessForm",
                        name: "BusinessForm",
                        component: Index,
                        meta: {
                            title: "自定义表单",
                            icon: "BusinessForm",
                            showInSideMenu: true,
                        },
                    },
                    {
                        path: "/custom-form-design",
                        name: "CustomFormDesign",
                        component: Design,
                        meta: {
                            title: "业务表单设计",
                            currentActiveMenu: "/businessForm",
                            icon: "BusinessForm",
                            showInSideMenu: false,
                        },
                    },

                    // 组织结构
                    {
                        path: "/entrustedmanagement/members",
                        name: "members",
                        component: () => import("@/views/Members"),
                        meta: {
                            menuTitle: "组织结构",
                            title: "成员与部门",
                            icon: "Members",
                            showInSideMenu: true,
                            rightIcon: "el-icon-money",
                        },
                    },

                    // {
                    //     path: "/entrustedmanagement/paramsSetting",
                    //     name: "ParamsSetting",
                    //     component: () => import("@/views/paramsSetting"),
                    //     meta: { title: "参数设置", requireAuth: true },
                    // },

                    // 结构管理
                    // {
                    //     path: "/entrustedmanagement/reportFormat",
                    //     name: "ReportFormat",
                    //     component: () => import("@/views/ReportFormat"),
                    //     meta: { menuTitle: "结构管理", title: "报告格式", icon: "detectionResourceService", showInSideMenu: true },
                    // },
                    // {
                    //     path: "/entrustedmanagement/presetConfig",
                    //     name: "PresetConfig",
                    //     component: () => import("@/views/PresetConfig"),
                    //     meta: { title: "数据配置", icon: "detectionResourceService", showInSideMenu: true },
                    // },

                    // // 系统管理
                    // {
                    //     path: "/entrustedmanagement/people",
                    //     name: "People",
                    //     component: () => import("@/views/People"),
                    //     meta: { menuTitle: "系统管理", title: "用户管理", icon: "detectionResourceService", showInSideMenu: true },
                    // },
                    // {
                    //     path: "",
                    //     name: "Options",
                    //     component: () => import("@/views/People"),
                    //     meta: { title: "选项配置", icon: "detectionResourceService", showInSideMenu: true },
                    // },

                    // 不显示
                    {
                        path: "/entrustedmanagement/missionForm",
                        name: "TaskForm",
                        component: () => import("@/views/Mission/CreateTask"),
                        meta: {
                            title: "新建任务",
                            showInSideMenu: false,
                        },
                    },
                    {
                        path: "/entrustedmanagement/missionEditForm",
                        name: "MissionEditForm",
                        component: () => import("@/views/Mission/EditTask"),
                        meta: {
                            title: "编辑任务",
                            showInSideMenu: false,
                        },
                    },
                    {
                        path: "/entrustedmanagement/missionProcess",
                        name: "MissionProcess",
                        component: () => import("@/views/Mission/MissionProcess"),
                        meta: {
                            title: "处理任务",
                            showInSideMenu: false,
                        },
                    },
                    {
                        path: "/entrustedmanagement/fileConfig",
                        name: "FileConfig",
                        // component: () => import("@/views/FileConfig"),
                        meta: {
                            title: "报告配置",
                            showInSideMenu: false,
                        },
                    },

                    {
                        path: "/entrustedmanagement/home",
                        name: "manageHome",
                        component: () => import("../views/Home"),
                        meta: {
                            title: "工作台",
                            showInSideMenu: false,
                        },
                    },

                    {
                        path: "/entrustedmanagement/help",
                        name: "Help",
                        component: () => import("@/views/Help"),
                        meta: {
                            title: "帮助",
                            showInSideMenu: false,
                        },
                    },

                    // {
                    //     path: "/entrustedmanagement/templateConfig",
                    //     name: "TemplateConfig",
                    //     component: () => import("@/views/TemplateConfig"),
                    //     meta: { title: "模板配置", showInSideMenu: false },
                    // },
                    {
                        path: "/fileEntry",
                        name: "FileEntry",
                        component: () => import("@/views/FileEntry"),
                        meta: {
                            title: "数据录入",
                            showInSideMenu: false,
                        },
                    },
                ],
            },
        ],
    },
    // 后台登录
    {
        path: "/",
        name: "Login",
        component: Login,
        beforeEnter(to, from, next) {
            store.commit("setDocumentFrontTitle")
            next()
        },
        meta: {
            title: "登录",
            icon: "appHeaderAlert",
            showInSideMenu: true,
        },
    },
    {
        path: "/Register",
        name: "Register",
        component: Register,
        meta: {
            title: "注册",
            icon: "appHeaderAlert",
            showInSideMenu: true,
        },
    },
    {
        path: "/MainPage",
        name: "MainPage",
        component: MainPage,
        identification: "mainRoute",
        meta: {
            title: "工作台",
            icon: "appHeaderAlert",
            showInSideMenu: true,
        },
        beforeEnter(to, from, next) {
            console.log("MainPage.beforeEnter")
            store.commit("setDocumentBackTitle")
            next()
        },
        children: [...stagingRoutes, ...TestResourcesRoutes, ...detectedDataRoutes, ...reportCertificateRoutes, ...dataAnalysisRoutes],
    },
    {
        path: OfflinePath,
        name: "Offline",
        component: Offline,
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})
//判断用户是否登录
/** 是否为初次加载当前token对应用户信息 */
let firstLoad = true
// 当前环境为electron 本地token存在 且为生产模式 清除token
if (isElectron && getLocalToken() && !isDev) {
    // console.log('electron 初次加载 先清除token');
    removeWindowToken()
    removeLocalToken()
    console.log(isElectron, getLocalToken(), isDev)
} else {
    // console.log('electron - else 初次加载 先清除token', isElectron, getLocalToken(), isDev);
}
/** 登录注册路径 */
let loginPaths = ["/", "/Register"]
// 路由守卫
router.beforeEach(async (to, from, next) => {
    console.log("to.path", to.path)
    /** query传递的token值 多用于系统或门户之间跳转 */
    let queryToken = to.query.token
    // query传递的token值存在 设置token至本地
    if (queryToken) {
        store.commit("setToken", queryToken)
        store.commit("setMentWeb", true)
        let query = to.query
        delete query.token
        next({
            ...to,
            query,
        })
        return
    }
    // 当前环境为electron 本地token存在 且为初次加载 直接设置当前窗口token
    else if (isElectron && getLocalToken() && firstLoad && isDev) {
        setWindowToken(getLocalToken())
    }

    let token = getWindowToken()
    /** 需要重定向的路由地址 */
    let redirect
    // 网络无连接  来源路由地址不是OfflinePath，此时需要跳转OfflinePath，并传入来源路由地址from.query.redirect或from.fullPath做redirect参数
    if (!window.navigator.onLine && to.path === OfflinePath && !to.query.redirect && from.path !== OfflinePath) {
        redirect = encodeURIComponent(from.fullPath)
        console.log("网络无连接, redirect=", redirect)
        next({
            path: OfflinePath,
            query: {
                redirect,
            },
            replace: true,
        })
    }
    // 网络无连接  目标路由是OfflinePath，此时需要跳转OfflinePath，并传入目标路由做redirect参数
    else if (!window.navigator.onLine && to.path !== OfflinePath && from.path === OfflinePath) {
        next({
            path: from.fullPath,
            replace: true,
        })
    } else if (!window.navigator.onLine && to.path !== OfflinePath && from.path !== OfflinePath) {
        redirect = encodeURIComponent(to.fullPath)
        console.log("网络无连接, redirect=", redirect)
        next({
            path: OfflinePath,
            query: {
                redirect,
            },
            replace: true,
        })
    } else if (!window.navigator.onLine) {
        next()
    }
    // 网络已连接 目标路由地址为OfflinePath，此时需要重定向
    else if (to.path === OfflinePath) {
        if (to.query.redirect) {
            redirect = decodeURIComponent(decodeURIComponent(to.query.redirect))
        } else {
            redirect = "/"
        }
        console.log("网络已连接, redirect=", redirect, to.fullPath)
        next({
            path: redirect,
            replace: true,
        })
    }
    // 当前窗口token存在
    else if (token) {
        if (firstLoad) {
            await Promise.all([store.dispatch("getUserInfo")])
            await store.dispatch("getUserModule")
            firstLoad = false
        }
        let routeMenu = store.state.routeMenu
        //去往登录/注册页 拦截并发送登录请求
        if (loginPaths.includes(to.path)) {
            let redirect = HomePath
            if (from.query.redirect && routeMenu.includes(from.query.redirect)) {
                redirect = decodeURIComponent(decodeURIComponent(from.query.redirect))
            }
            // console.log("token.redirect", redirect)
            next({
                path: redirect,
                replace: true,
            })
        } else if (to.path === HomePath && from.query.redirect && routeMenu.includes(from.query.redirect) && from.query.redirect != HomePath) {
            // console.log("token.redirect", redirect)
            next({
                path: decodeURIComponent(decodeURIComponent(from.query.redirect)),
            })
        } else {
            if (routeMenu.length && routeMenu.includes(HomePath) && !routeMenu.includes(to.path)) {
                MessageBox.alert("你没有该页面相关权限", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                    customClass: "labMessageClass",
                })
                console.log("路由权限拦截",routeMenu, to.path, routeMenu.includes(HomePath), routeMenu.includes(to.path))
                router.push({
                    path: HomePath,
                })
            } else {
                next()
            }
        }
    }
    // 当前窗口token不存在
    else {
        if (loginPaths.includes(to.path)) {
            firstLoad = true
            if (isElectron) {
                ipcRenderer.send("setLoginWindow")
                next()
            } else {
                if (store.state.isMentWeb) {
                    let url = ifrServerLogout
                    store.commit("setMentWeb", false)
                    location.href = `${url}`
                    // location.href = `${url}?redirect=${encodeURIComponent("/entrustedmanagement/home")}`
                } else {
                    next()
                }
            }
        } else {
            next({
                path: "/",
                query: {
                    redirect: to.fullPath,
                },
            })
        }
    }
})
/** 主路由是否已经加载完成 */
let firstMainRouteLoaded = false
router.afterEach((to, from) => {
    let token = getWindowToken()
    if (token && !firstMainRouteLoaded) {
        console.log("afterEach.firstMainRouteLoaded-start", to.path, from.path, router.currentRoute.fullPath)
        if (isElectron && loginPaths.includes(from.path) && !loginPaths.includes(to.path)) {
            firstMainRouteLoaded = true
            console.log("afterEach.firstMainRouteLoaded")
            setTimeout(() => {
                ipcRenderer.send("setMainWindow")
            }, 200)
        }
    } else if (!token) {
        firstMainRouteLoaded = false
    }
})

window.addEventListener("offline", () => {
    router.push({
        path: OfflinePath,
    })
})
window.addEventListener("online", () => {
    /** 网络连接时，需要重定向的路由 */
    let redirect = router.currentRoute.query.redirect
    if (redirect) {
        redirect = decodeURIComponent(decodeURIComponent(redirect))
    } else {
        redirect = "/"
    }
    router.push({
        path: redirect,
    })
})
export default router
export const routerMenu = routes[0].children[0].children.filter(item => item.meta.showInSideMenu)
export { routes, BusinessDataRoutes, HomePath, OfflinePath }
