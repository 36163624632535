import { componentsDefaultValue } from '../../constant';
const getFormProps = (formConfig) => {
    let { schemas, model, actionColOptions } = formConfig;
    model = model || {};
    // for (let i = 0; i < schemas.length; i++){
    //     let { prop, component } = schemas[i];
    //     model[prop] = componentsDefaultValue[component];
    // }
    return  {
        labelPosition: 'right',
        labelWidth: '100px',
        
        // model,
        ...formConfig,
        useSearchForm: true,
        actionColOptions: {
            // colProps: {
            //     span: 4,
            // },
            showActionGroup: true,
            showSearchButton: true,
            showResetButton: true,
            buttonGroupLayout: ['search', 'reset'],
            ...actionColOptions || {},
        },
        schemas: schemas.map(item => {
            return {
                required: false,
                ...item,
            }
        })
    };
}

const getTableProps = ($attrs) => {
    let props = $attrs;
    return {
        tooltipEffect: "dark",
        maxHeight: '100%',
        border: false,
        // size: 'medium',
        rowKey: 'id',
        
        highlightCurrentRow: true,
        // headerRowStyle: {},
        headerCellStyle: {
            background: '#f8f8f8',
            // fontSize: "14px",
            color: "#333333",
            // fontWeight: "bold",
        },
        ...props,
    };
}

const getTableColumnProps = (column) => {
    let { type } = column;
    return {
        showOverflowTooltip: typeof type === 'undefined',
        headerAlign: 'left',
        align: 'left',
        ...column,
    }
}

export {
    getFormProps,
    getTableProps,
    getTableColumnProps,
}