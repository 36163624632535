import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
// import '../utils/directive';
import "./utils/directive"
import "./assets/css/routeForm.less"
import "./assets/css/form.less"
import "./assets/css/new.less"
import "./assets/icons/index.js"
import "./assets/css/my-pm.less"
import "./assets/css/svg-icon.less"
import "./assets/css/button.less"

import VueDND from 'awe-dnd'
import axios from "axios"
import api from '../src/api/api.js'
import Rsa from "@/utils/rsa.js"

import '@/core/index';

Vue.prototype.$axios = axios;
Vue.use(VueDND)
Vue.use(ElementUI);
Vue.prototype.Rsa = Rsa // 将Rsa注册为公共方法,方便其他页面调用
Vue.prototype.api = api;
Vue.prototype.$store = store
Vue.config.productionTip = false

import mixin from '@/core/mixin';
Vue.mixin(mixin);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
