<template>
    <Input v-bind="getInputProps" v-model="value" ref="InputRef" :class="getClass" @blur="onBlur" @focus="onFocus" @change="onChange" @input="onInput" @clear="onClear">
        <template slot="prefix">
            <slot name="prefix"></slot>
        </template>
        <template slot="suffix">
            <slot name="suffix"></slot>
        </template>
        <template slot="prepend">
            <slot v-if="slotKeys.includes('prepend')" name="prepend"></slot>
            <template v-else-if="getInputProps.prepend">{{ getInputProps.prepend }}</template>
        </template>
        <template slot="append">
            <slot v-if="slotKeys.includes('append')" name="append">{{ getInputProps.append || '' }}</slot>
            <template v-else-if="getInputProps.append">{{ getInputProps.append }}</template>
        </template>
    </Input>
</template>

<script>
import { Input } from 'element-ui';
import { prefixCls } from './constant';
import { useInputProps } from './hooks';
export default {
    name: 'BasicInput',
    components: {
        Input,
    },
    model: {
        prop: "modelValue",
        event: "input",
    },
    props: {
        /* public */
        modelValue: {
            type: String,
            default: "",
        },
    },
    mounted(){
        // console.log('basic-input', this.$attrs, this.modelValue);
    },
    computed: {
        getClass(){
            return prefixCls;
        },
        getInputProps(){
            return useInputProps(this.$attrs);
        },
        slotKeys(){
            return Object.keys(this.$slots);
        },
        value: {
            get(){
                return this.modelValue;
            },
            set(value){
                // console.log('BasicInput.computed.value.set', value);
                this.$emit('input', value);
            }
        }
    },
    methods: {
        onBlur(e){
            this.$emit('blur', e);
        },
        onFocus(e){
            this.$emit('focus', e);
        },
        onChange(value){
            this.$emit('change', value);
        },
        onInput(value){
            // console.log('BasicInput', value);
            // this.$emit('input', value);
        },
        onClear(e){
            this.$emit('blur', e);
        },
        focus(){
            this.$refs.InputRef.focus();
        },
        blur(){
            this.$refs.InputRef.blur();
        },
        select(){
            this.$refs.InputRef.select();
        }

    }
}
</script>

<style>

</style>