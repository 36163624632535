<template>
    <TableColumn v-bind="getTableColumnProps">
        
    </TableColumn>
    
</template>

<script>
import { TableColumn } from 'element-ui';
import { getTableColumnProps } from './hooks';
export default {
    name: 'BasicColumn',
    components: {
        TableColumn,
    },
    props: {
        prop: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => [],
        },
        optionProps: {
            type: Object,
            default: () => {
                return {
                    label: 'label',
                    value: 'value',
                }
            }
        }
    },
    data(){
        return {

        }
    },
    computed: {
        getTableColumnProps(){
            return getTableColumnProps(this.$attrs);
        },
        
    },
    methods: {
        getComputedValue(row){
            console.log('getComputedValue', row);
            let find = this.options.find(item => item[this.optionProps.value] === row[this.prop]);
            if (find){
                return find[this.optionProps.label]
            } else {
                return row[this.prop]
            }
        },
        getComputedValueAttrs(row){
            let find = this.options.find(item => item[this.optionProps.value] === row[this.prop]);
            if (find && find.style){
                return find;
            } else {
                return {}
            }
        }
    }
}
</script>

<style>

</style>