import router from "@/router"
import store from "@/store"
import cookie from "vue-cookie"
/** cookie键名枚举 */
const COOKIEKEYS = {
    /** 登录时间戳键名 */
    LOGINSTAMP: "LOGINSTAMP",
}
/**
 * 获取当前窗口token
 * @returns {String} 当前窗口token
 */
const getWindowToken = () => {
    // return sessionStorage.getItem('token');
    // console.log('getWindowToken', localStorage.getItem('token'));
    if (!cookie.get(COOKIEKEYS.LOGINSTAMP)) {
        removeLocalToken()
        removeWindowToken()
    }
    let token = localStorage.getItem("token")
    if (token && token.includes("?token=")) {
        token = token.split("?token=")[0]
        store.commit("setToken", token.split(" ")[1])
    }
    // console.log("fdgdfggfhfhfhfhfh", token)
    return token
}

/**
 * 设置当前窗口token
 */
const setWindowToken = token => {
    // sessionStorage.setItem('token', token);
    localStorage.setItem("token", token)
    cookie.set(COOKIEKEYS.LOGINSTAMP, Date.now())
}

/**
 * 清除当前窗口token
 */
const removeWindowToken = () => {
    // sessionStorage.setItem('token', token);
    localStorage.removeItem("token")
    console.log("removeWindowToken", localStorage.getItem("token"))
}

/**
 * 设置当前窗口merberId
 */
const setWindowMerberId = memberId => {
    sessionStorage.setItem("memberId", memberId)
}

/**
 * 设置当前窗口userphone
 */
const setWindowUserphone = userphone => {
    sessionStorage.setItem("userphone", userphone)
}
/**
 * 获取当前窗口merberId
 */
const getWindowMerberId = () => {
    return sessionStorage.getItem("memberId")
}
/**
 * 获取当前窗口userphone
 */
const getWindowUserphone = () => {
    return sessionStorage.getItem("userphone")
}

/**
 * 获取本地token
 */
const getLocalToken = () => {
    return localStorage.getItem("xlzytoken")
}

/**
 * 设置本地token
 */
const setLocalToken = token => {
    localStorage.setItem("xlzytoken", token)
}

/**
 * 清除本地token
 */
const removeLocalToken = () => {
    // sessionStorage.setItem('token', token);
    localStorage.removeItem("xlzytoken")
    console.log("removeLocalToken", localStorage.getItem("xlzytoken"))
}

/**
 * 当前窗口token是否存在
 */
const validateWindowToken = () => {
    return !!getWindowToken()
}

/**
 * 验证当前窗口是否为有效窗口，若有其他后续登录过的窗口存在， 则当前窗口应该被强制退出登录 （判断当前窗口token是否和本地窗口token一致）
 */
const validateWindowUniqueToken = () => {
    return getWindowToken() === getLocalToken()
}
/**
 * 清楚第一次登录
 */
const removeWindowFist = () => {
    sessionStorage.removeItem("memberId")
    sessionStorage.removeItem("userphone")
}
/**
 * 清除当前窗口token 并且进入登录页带上redirect参数  在窗口token存在但不是有效token 或者 窗口token失效时 执行
 */
const removeWindowTokenAndRedirect = () => {
    // sessionStorage.removeItem('token');
    localStorage.removeItem("token")
  
    if (router.currentRoute.path !== "/") {
        let { redirect, ...querys } = router.currentRoute.query
        let { href } = router.resolve({
            path: router.currentRoute.path,
            query: querys,
        })
        router.push({
            path: "/",
            query: {
                redirect: href,
            },
        })
    }
}

export {
    getWindowToken,
    setWindowToken,
    removeWindowToken,
    removeWindowTokenAndRedirect,
    getLocalToken,
    setLocalToken,
    removeLocalToken,
    setWindowMerberId,
    validateWindowToken,
    setWindowUserphone,
    getWindowUserphone,
    getWindowMerberId,
    validateWindowUniqueToken,
    removeWindowFist,
}
