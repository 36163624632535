const rootPrefixCls = 'lab';
let componentsMap = {
    'Input': 'Input',
    'InputNumber': 'InputNumber',
    'Text': 'Text',
    // 'Divider': 'ItemDivider',
    'Divider': 'Divider',
    'Select': 'Select',
    'RadioGroup': 'RadioGroup',
    'CheckboxGroup': 'CheckboxGroup',
    'Switch': 'BasicSwitch',
    'DatePicker': 'DatePicker',
    'TimePicker': 'TimePicker',
    'Upload': 'Upload',
}

let componentsDefaultValue = {
    'Input': '',
    'InputNumber': '',
    'Text': '',
    'Select': '',
    'Radio': '',
    'Checkbox': [],
    'DatePicker': [],
}

let componentsDefaultStyle = {
    'Input': '',
    'Text': '',
    'Select': '',
    'Radio': '',
    'Checkbox': [],
    'DatePicker': {
        width: '230px',
    },
}
let componentsDefaultProps = {
    // 'InputNumber': {

    // },
    'DatePicker': {
        'type=daterange': {
            rangeSeparator: "至",
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
        }
    },
    'Input': {
        'type=textarea': {
            autosize: true
        }
    }
}

let getLastPropertyValue = (obj, prop) => {
    let res = obj;
    let currProp;
    let props = path.split('.');
    // [a, b, c, d]
    while (currProp = props.shift()) {
        if (!res[currProp]) {
            return undefined
        } else {
            res = res[currProp]
        }

    }
    return res
}

export {
    rootPrefixCls,
    componentsMap,
    componentsDefaultValue,
    componentsDefaultStyle,
    componentsDefaultProps,
}