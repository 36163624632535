<template>
    <Drawer :class="getClass" :visible="Visible" v-bind="getDrawerProps" ref="DialogRef" @open="open" @opened="opened" @close="close" @closed="closed">
        <template slot="title">
            <slot name="title">
                {{ title }}
                <!-- <slot name="header-title">
                    <span @click="close">关闭</span>
                </slot>

                <div class="header-action">
                    <slot name="header-action">
                        <Button type="primary" size="small" @click="handleConfirm">确定</Button>
                        <Button type="sub" size="small" @click="handleReset">重置</Button>

                    </slot>
                </div> -->
            </slot>
        </template>
        <div :class="getClass + '__body'">
            <BasicForm v-if="formConfig" :model="model" v-bind="getFormProps" ref="FormRef">
                <template v-for="key in slotKeys" :slot="key">
                    <slot :name="key"></slot>
                </template>
            </BasicForm>
            <slot v-else></slot>
        </div>

        <div v-if="showFooter" :class="getClass + '__footer'">
            <Button @click="handleConfirm">确定</Button>
            <Button @click="handleCancel">取消</Button>
        </div>
    </Drawer>
</template>

<script>
import { Drawer, Button } from "element-ui"
// import LabButton from '../../Button/index';
import BasicForm from "@/components/FormDesign/Components/Form"
import { prefixCls } from "./constant"
import { useDrawerProps, useFormProps } from "./hooks"
export default {
    name: "BasicDrawer",
    components: {
        Drawer,
        LabButton: Button,
        Button,
        BasicForm,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        model: {
            type: Object,
            default: () => {
                return {}
            },
        },
        formConfig: {
            type: Object,
            default: () => {
                return null
            },
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        closeOnCancel: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            /** 点击取消 自定义关闭 先使用$emit触发close来设置visible为false，然后会触发drawer的close和closed事件 */
            innerCustomClose: false,
        }
    },
    mounted() {
        console.log("Drawer.mounted", this.getFormProps, this.slotKeys, this.getDrawerProps)
    },
    computed: {
        getClass() {
            return prefixCls
        },
        Visible: {
            get() {
                    
                
                return this.visible
            },
            set() {},
        },
        getDrawerProps() {
            if (this.title.includes("查看") || this.title.includes("详情")) {
                return useDrawerProps({ ...this.$attrs, wrapperClosable: true })
            }
            return useDrawerProps(this.$attrs)
        },
        getFormProps() {
            return {
                labelWidth: "80px",
                labelPosition: "left",
                ...useFormProps(this.formConfig || {}),
            }
        },
        slotKeys() {
            return Object.keys(this.$slots)
        },
    },
    watch: {
        Visible: {
            handler(value) {
                if (value) {
                    this.$nextTick(() => {
                        // this.clearValidate()
                        this.resetFields();
                    })
                }
            },
            immediate: false,
        },
    },
    methods: {
        resetFields() {
            this.$refs.FormRef && this.$refs.FormRef.resetFields()
        },
        clearValidate() {
            console.log("clearValidate", this.$refs.FormRef)
            this.$refs.FormRef && this.$refs.FormRef.clearValidate()
        },
        validate(...args) {
            return this.$refs.FormRef.validate(...args)
        },
        handleReset() {
            this.resetFields()
            this.$emit("reset")
        },
        async handleConfirm() {
            if (this.formConfig) {
                let valid = await this.$refs.FormRef.validate()
                if (!valid) return
            }
            this.$loading()
            this.$emit("confirm", { ...this.model })
            this.$loading().close()
        },
        open() {
            // console.log('open');
            this.$emit("open")
        },
        opened() {
            // console.log('opened');
            this.$emit("opened")
        },
        close() {
            // console.log('BasicDrawer, close');
            // 当前回调是点击右上角关闭图标触发，不是点击取消按钮
            if (!this.innerCustomClose){
                this.$emit("close");
            }
            // 点击取消按钮，执行handleCancel、handleCustomClose，emit触发父组件事件设置visible为false，避免重复触发emit('close')，使用innerCustomClose来取分
            else {
                this.innerCustomClose = false;
            }
            // this.$emit("close")
            // this.resetFields();
            // this.clearValidate()
        },
        closed() {
            // console.log('BasicDrawer, closed');
        },
        handleCancel(){
            if (this.closeOnCancel){
                this.handleCustomClose();
            } else {
                this.$emit("cancel");
            }
        },
        handleCustomClose(){
            // console.log('BasicDrawer, handleCustomClose');
            this.innerCustomClose = true;
            this.$emit("close");
        }
    },
}
</script>

<style lang="less">
@import url("./index.less");
</style>
