export const useDrawerProps = ($attrs) => {
    return {
        size: 600,
        showClose: true,
        direction: 'rtl',
        modal: false,
        closeOnPressEscape: false,
        'close-on-click-modal': false,
        'destroy-on-close':true,
        wrapperClosable: false,
        modalAppendToBody: false,
        ...$attrs,
    }
}

export const useFormProps = (formConfig) => {
    let { schemas, actionColOptions } = formConfig;
    schemas = schemas || [];
    for (let i = 0; i < schemas.length; i++){
        schemas[i]['colProps'] = {
            span: 24,
            offset: 0,
            ...schemas[i]['colProps'] || {}
        }
    }
    formConfig = {
        ...formConfig,
        actionColOptions: {
            showActionGroup: false,
            ...actionColOptions || {},
        },
        schemas,
    };
    // console.log('Drawer/src/hooks.useFormProps', formConfig);
    return formConfig;
}