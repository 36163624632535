<template>
    <el-dialog class="verifyConfirmDialog my-dialog" v-dialogDrag :title="title" :visible.sync="visible" :modal-append-to-body="false" @close="handleClose" :show-close="false">
        <el-row :span="24">
            <el-form ref="form_ref" :model="formData" :rules="rules" size="small">
                <el-col :span="24">
                    <el-col :span="24" class="mb-20">
                        <el-alert class="my-alert" type="error" effect="dark" :closable="false">
                            <template #title>
                                <span class="fw-bold">警告：删除后数据无法恢复！</span>
                            </template>
                        </el-alert>
                    </el-col>
                    <el-col :span="24" class="mb-20">
                        <el-alert style="padding-bottom: 10px !important" class="my-alert pb-20 pt-10" type="error" effect="dark" :closable="false">
                            <template #title>
                                <div v-if="alertMessage" class="mb-20">{{ alertMessage }}</div>
                                <div>为防止意外，确认继续操作请输入您的密码。</div>
                            </template>
                        </el-alert>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="password" label-width="0" class="ml-0">
                            <el-input type="password" show-password v-model="formData.password" placeholder="请输入验证密码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-form>
        </el-row>

        <div class="my-dialog-footer align-right">
            <el-button class="my-primary-button" @click="handleSubmit">确 定</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { Dialog, Row, Col, Form, Input, Button } from "element-ui"
import { checkPassword } from "../../../api/main"
import { encryptionStr } from "../../../utils/utils"
import { MessageBox, Message } from "element-ui"
export default {
    name: "verifyConfirm",
    components: {
        "el-dialog": Dialog,
        "el-row": Row,
        "el-col": Col,
        "el-form": Form,
        "el-input": Input,
        "el-button": Button,
        
    },
    data() {
        return {
            title: "验证密码",
            visible: false,
            alertMessage: "该操作将可能造成数据丢失或原始记录/报告生成异常！",
            formData: {
                password: "",
            },
            rules: {
                password: [{ required: true, message: "请输入验证密码", trigger: "none" }],
            },
            successCallback: null,
        }
    },
    created() {
        console.log("created, verifyConfirm")
    },
    mounted() {
        console.log("mounted, verifyConfirm")
    },
    watch: {
        visible(val) {
            console.log("verifyConfirm, watch, visible")

            if (val) {
            } else {
                this.$refs.form_ref.resetFields()
            }
        },
    },
    methods: {
        handleClose() {
            this.visible = false
        },
        async handleSubmit() {
            let verifyPromise = new Promise(resolve => {
                this.$refs.form_ref.validate(valid => {
                    resolve(valid)
                })
            })
            let verify = await verifyPromise
            if (!verify) return
            checkPassword(encryptionStr(this.formData.password))
                .then(() => {
                    if (typeof this.successCallback === "function") {
                        this.successCallback()
                    } else {
                        console.log("successCallback--回调函数未定义")
                    }
                    this.visible = false
                })
                .catch(error => {
                    console.log("error", error.message)
                    MessageBox.alert("验证失败！", "提示", {
                        confirmButtonText: "确定",
                        type: "warning",
                        showClose: true,
                        customClass: "labMessageClass",
                    })
                        .then(() => {})
                        .catch(() => {})
                    // if (typeof error.message === 'string' && error.message === '数据错误'){

                    // }
                })
        },
    },
}
</script>
<style lang="less">
.verifyConfirmDialog {
    & > .el-dialog {
        width: 600px;
        // height: 385px;
        & > .el-dialog__body {
            padding: 25px 35px;
        }
        & div {
            box-sizing: border-box;
        }
    }
}
</style>