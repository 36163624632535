//choiceObject.js文件
import Vue from "vue"
import FileTask from "./index.vue"

const $app = Vue.extend(FileTask)
const $FileTask = new $app().$mount(document.createElement("div"))

document.body.appendChild($FileTask.$el)

export default {
    install(vm) {
        vm.prototype.$FileTask = {
            show: params => {
                params &&
                    Object.keys(params).forEach(key => {
                        $FileTask[key] = params[key]
                    })
                $FileTask.visible = true
            },
            hide: () => {
                $FileTask.visible = false
            },
            reset: () => {
                $FileTask.resetData()
            },
            refresh: () => {
                $FileTask.GetFileData()
            },
        }
    },
}
