<template>
    <RadioGroup v-bind="$attrs" v-model="value" :class="getRadioGroupClass">
        <Radio :class="getRadioClass" v-for="item in getOptions" :key="item.index" v-bind="item">{{ item.text }}</Radio>
    </RadioGroup>
</template>

<script>
import { RadioGroup, Radio } from 'element-ui';
import { prefixCls } from './constant';
import { getUuid } from '../../utils';
export default {
    name: 'BasicRadioGroup',
    components: {
        RadioGroup, Radio,
    },
    model: {
        prop: "modelValue",
        event: "input",
    },
    props: {
        /* public */
        modelValue: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {
                    label: 'label',
                    value: 'value',
                }
            }
        }
    },
    mounted(){
        // console.log('basic-input', this.$attrs, this.modelValue);
    },
    computed: {
        getRadioGroupClass(){
            return prefixCls + '-group';
        },
        getRadioClass(){
            return prefixCls;
        },
        getOptions(){
            console.log('options', this.options);
            return this.options.map(item => {
                return {
                    ...item,
                    index: getUuid(32, 36),
                    text: item[this.defaultProps.label],
                    label: item[this.defaultProps.value],
                }
            })
        },
        value: {
            get(){
                return this.modelValue;
            },
            set(value){
                // console.log('BasicInput.computed.value.set', value);
                this.$emit('input', value);
            }
        }
    },
    methods: {
    }
}
</script>