<template>
    <div id="my-upload">
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[images]" />
        <el-upload
            action="/"
            :class="disabled ? 'disable_button' : ''"
            :multiple="multiple"
            :disabled="disabled"
            :limit="limit"
            :http-request="file => httpUpload(file)"
            :on-exceed="file => onExceed(file)"
            :file-list="fileList"
            :show-file-list="false"
            :accept="accept"
            :before-upload="beforeUpload"
        >
            <el-button class="able_button" size="small">点击上传</el-button>
            <div v-if="showTip && tipText" slot="tip" class="el-upload__tip">{{ tipText }}</div>
        </el-upload>
        <div class="imgList" v-if="fileList.length || !load">
            <div class="img_item" v-for="(item, index) in fileList" :key="index">
                <p  @click="handleFileClick(item)">
                    <span><svg-icon :style="{ fontSize: '16px' }" icon-class="picture"></svg-icon></span>
                    {{ item.name }}
                </p>
                <el-button v-if="!disabled" size="small" type="text" @click="handleRemove(item)">删除</el-button>
            </div>
        </div>
        <div v-if="load" class="imggif">
            <img src="./load.gif" alt="" />
        </div>
        <div v-if="disabled && fileList.length == 0" class="imgList">
            <div class="img_item">
                <p>暂无数据</p>
            </div>
        </div>
    </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer"
import { previewReport, getFileUrl } from "@/api/main"
import { MessageBox } from "element-ui"
export default {
    name: "MyUpload",
    components: { ElImageViewer },
    model: {
        prop: "modelValue",
        event: "change",
    },
    props: {
        /* public */
        modelValue: {
            type: [Array, String],
            default: [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        /** 自定义删除方法，返回false或返回Promise并reject，则删除失败，其他情况删除成功 */
        beforeRemove: {
            type: Function,
            default: (...args) => {
                console.log('beforeRemove', ...args);
            }
        },
        limit: {
            type: Number,
            default: 1,
        },
        /** 文件大小 */
        fileSize: {
            type: Number,
            default: 10,
        },
        /** 文件大小单位 */
        sizeUnit: {
            type: String,
            default: 'MB',
        },
        accept: {
            type: String,
            default: ".pdf, .png, .jpg, .jpeg, .bmp",
        },
        /** 是否展示文件上传提示 */
        showTip: {
            type: Boolean,
            default: false,
        },
        /** 文件上传提示内容 */
        tipText: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showViewer: false,
            images: [],
            url: "",
            load:false,
            fileList: [],
            sizeUnits: [
                { unit: 'KB', size: 1024 },
                { unit: 'MB', size: 1024 * 1024 },
                { unit: 'GB', size: 1024 * 1024 * 1024 },
            ],
        }
    },
    watch:{
        modelValue: {
            handler(modelValue) {
                console.log('MyUpload.watch.modelValue', modelValue);
                if (Array.isArray(modelValue)){
                    this.fileList = modelValue;
                } else {
                    this.fileList = [];
                }
            },
            immediate: true,
        },
        loading(val){
            this.load=val;
            //根据请求时间超时 timeout 超时加载失败
            if (val) {
                setTimeout(() => {
                    this.load=false
                }, 30*1000);
            }
        }
    },
    methods: {
        //列表图片点击
        async handleFileClick(item) {
            const fileSuffix = item.name.substring(item.name.lastIndexOf(".") + 1)
            if (this.preview && fileSuffix != "pdf") {
                const { data: url } = (await getFileUrl({ fileName: item.url })) || ""
                this.images = [url]
                this.onPreview()
            } else {
                if (item.url) {
                    //中文编码处理
                    let baseStr = btoa(String.fromCharCode(...new TextEncoder().encode(item.url)))
                    previewReport({
                        fileUrl: baseStr,
                        fileName: item.name,
                    }).then(res => {
                        this.$SetFile.show({
                            url: res.data,
                            isOriginal: true,
                            successCallback: e => {},
                        })
                    })
                }
            }
        },
        httpUpload(params) {
            console.log('httpUpload', params);
            let { file } = params;
            this.$emit("change", [
                ...this.fileList,
                file
            ])
        },
        //文件超出限制
        onExceed() {
            MessageBox.alert(`最多只能上传${ this.limit }个文件`, "提示", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: true,
            });
        },
        //大图
        onPreview() {
            this.showViewer = true
        },
        // 关闭查看器
        closeViewer() {
            this.images = []
            this.showViewer = false
        },
        /**
         * @param {Object} file 文件上传成功之前的回调
         * @param {String} file.name 文件名称
         * @param {Number} file.size 文件大小 单位B
        */
        beforeUpload(file) {
            const fileSuffix = "." + file.name.substring(file.name.lastIndexOf(".") + 1)
            console.log('beforeUpload', this.accept);
            const whiteList = this.accept.split(",").map(item => item.trim())
            if (whiteList.indexOf(fileSuffix) === -1) {
                MessageBox.alert(`文件只能是${whiteList.join("、")}格式`, "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                })
                return false
            }
            let findItem = this.sizeUnits.find(item => item.unit === this.sizeUnit);
            if (!findItem) return false;
            /** 文件大小最大值 */
            let maxSize = this.fileSize * findItem.size;
            if (Number(file.size) > maxSize) {
                MessageBox.alert(`文件大小不能超过${this.fileSize}MB，请重新上传。`, "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                })
                return false
            }
            return true;
        },
        handleRemove(item){
            let remove = () => {
                this.fileList.splice(this.fileList.indexOf(item), 1);
                this.$emit('change', this.fileList);
            }
            if (!typeof this.beforeRemove !== 'function'){
                return remove();
            }
            let flag = this.beforeRemove(item);
            if ((typeof flag === 'boolean' && !flag)) return;
            if (Object.prototype.toString.call(flag) === '[object Promise]'){
                flag.then(() => {
                    remove();
                }).catch(error => {

                })
            }
        },
    },
    
}
</script>

<style lang="less">
#my-upload {
    .able_button {
        background-color: #3074ee;
        color: white;
    }
    .disable_button .el-upload--text {
        display: none;
    }
    & .el-upload__tip{
        font-size: 12px!important;
        line-height: normal;
    }
    .imgList {
        .img_item {
            &:hover {
                background: #f5f7fa;
            }
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                padding-left: 4px;
                font-size: 14px;
                width: calc(100% - 26px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #606266;
                span {
                    margin-right: 4px;
                }
            }
            & .el-button{
                font-size: 14px;
            }
        }
    }
    .imggif{
        height: 22px;
        text-align: center;
        img{
            height: 100%;
        }
    }
}
.el-image-viewer__wrapper {
    z-index: 3000 !important;
    position: absolute;
    .el-image-viewer__canvas {
        position: absolute;
        height: 80%;
        top: 10%;
    }
}
</style>
