<template>
	<div class="context-menu-refer" @contextmenu="openContextMenu">
		<slot></slot>
	</div>
</template>

<script>
import { openContextMenu } from './contextMenuList';
// import { emit } from 'Vue';
// Vue.prototype.$emit
export default {
	name: 'ContextMenu',
	props: {
		contextMenuList: {
			type: Array,
			default: () => {
				return []
			}
		},
		menuItemClick: {
			type: Function,
			default: () => {
				return (...args) => {
					// console.log('openContextMenu.menuItemClick', ...args);
					let { emitEvent } = args;
					if (typeof emitEvent === 'string'){
						Vue.prototype.$emit(emitEvent, ...args);
					}
				}
			}
		}
	},
	data(){
		return {

		}
	},
	mounted(){
		// console.log('ContextMenu', this.contextMenuList);
	},
	methods: {
		/** 
		 * @param {Event} evt
		 * @description 右键菜单
		 */
		openContextMenu(evt){
			evt.preventDefault();
			// console.log('openContextMenu', evt);
			let top = 0;
			let left = 0;
			if (evt.clientX){
				left = evt.clientX + 'px';
				console.log('left', left);
			}
			if (evt.clientY){
				top = evt.clientY + 'px';
				console.log('top', top);
			}
			openContextMenu({
				contextMenuList: this.contextMenuList,
				position: {
					top,
					left
				},
				menuItemClick: (...args) => {
					// console.log('openContextMenu.menuItemClick', ...args, args[0]);
					let { emitEvent } = args[0];
					if (typeof emitEvent === 'string'){
						this.$emit(emitEvent, ...args);
					}
				},
			})
			// var x = evt.clientX;
			// var y = evt.clientY;
		}
	}
}
</script>

<style lang="less">
#context-menu{
	// position: ;
}
</style>