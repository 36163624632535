<template>
    <CheckboxGroup v-bind="$attrs" v-model="value" ref="CheckboxRef" :class="getCheckboxGroupClass" @change="onChange">
        <Checkbox :class="getCheckboxClass" v-for="item in getOptions" :key="item.index" v-bind="item" @change="value => handleCheckboxChange(value, item)">
            {{ item.text }}
        </Checkbox>
    </CheckboxGroup>
</template>

<script>
import { CheckboxGroup, Checkbox } from 'element-ui';
import { prefixCls } from './constant';
import { getUuid } from '../../utils';
export default {
    name: 'BasicCheckboxGroup',
    // inject: ['getSchemas'],
    components: {
        CheckboxGroup, Checkbox,
    },
    model: {
        prop: "modelValue",
        event: "change",
    },
    props: {
        /* public */
        modelValue: {
            type: Array,
            default: () => {
                return []
            },
        },
        // prop: {
        //     type: String,
        //     default: '',
        // },
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {
                    label: 'label',
                    value: 'value',
                }
            }
        },
        hideCheckInput: {
            type: Boolean,
            default: false,
        },
        selectAllClearOthers: {
            type: Boolean,
            default: false,
        }
    },
    mounted(){
        // console.log('BasicCheckboxGroup.mounted', this.$attrs, this.modelValue);
    },
    computed: {
        getCheckboxGroupClass(){
            return prefixCls + '-group';
        },
        getCheckboxClass(){
            return prefixCls + ( this.hideCheckInput ? ' hide-check-input' : '' )
        },
        getOptions(){
            // console.log('options', this.options);
            return this.options.map(item => {
                return {
                    ...item,
                    index: getUuid(32, 36),
                    text: item[this.defaultProps.label],
                    label: item[this.defaultProps.value],
                }
            })
        },
        value: {
            get(){
                return this.modelValue;
            },
            set(value){
                !this.selectAllClearOthers && this.$emit('change', value);
            }
        },
        schemas(){
            return this.getSchemas();
        }
    },
    watch: {
        getOptions: {
            handler(value){
                console.log('Checkbox.watch.getOptions', value);
            },
            immediate: true
        },
        // prop: {
        //     handler(value){
        //         console.log('BasicCheckboxGroup.watch.prop', value);
        //     },
        //     immediate: true,
        // },
        // schemas: {
        //     handler(value){
        //         console.log('BasicCheckboxGroup.watch.schemas', value);
        //     },
        //     immediate: true,
        // },
    },
    methods: {
        onChange(value, e){
            console.log('onChange', value);
            // if (this.selectAllClearOthers){
            //     console.log('onChange', value);
            // }
            // this.$emit('change', value, e);
        },
        handleCheckboxChange(value, item){
            if (!this.selectAllClearOthers) return;
            let itemValue = item[this.defaultProps.value];
            let findAll = this.value.find(Item => !Item);
            console.log('handleCheckboxChange', itemValue, findAll, this.value, item, value);
            if (!value){
                console.log('取消勾选');
                this.$emit('change', this.value.filter(Item => Item !== itemValue));
            }
            else if (!itemValue) {
                console.log('勾选全部');
                this.$emit('change', [itemValue]);
            }
            else {
                console.log('勾选非全部');
                this.$emit('change', [...this.value, itemValue].filter(Item => !!Item));
            }
        }
    }
}
</script>

<style>

</style>