//index.js文件
import Vue from 'vue'
import contentMenuList from './contextMenuList.vue';

const $app = Vue.extend(contentMenuList);

const $contentMenuList = new $app().$mount(document.createElement('div'))


document.body.appendChild($contentMenuList.$el)

/** 打开右键菜单 */
let openContextMenu = function (options) {
    // console.log('cropperImageFunction', this);
    this.open({
        ...options || {}
    });
}
openContextMenu = openContextMenu.bind($contentMenuList);
// .bind($cropperImage);

// cropperImageFunction.preview = function (options) {
//     this.options = {
//         ...options,
//         mode: 'preview'
//     };
// }
export {
	openContextMenu
}

export default {
    install(vm) {
        vm.prototype.$openContextMenu = openContextMenu;
    }
    // /$cropperImage.visible = false
}