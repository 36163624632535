<template>
    <div id="app" style="'--theme--':green ">
        <router-view />
    </div>
</template>
<script>
export default {
    name: "App",
    mounted() {
        // 在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem("store") && !this.$route.params.token) {
            //this.$store.replaceState是vue官方提供的一个api表示替换 store 的根状态
            //里面的Object.assign()表示将store中的状态和sessionStorage中的状态进行合并
            this.$store.replaceState(Object.assign({}, JSON.parse(sessionStorage.getItem("store")), this.$store.state))
        }
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        // beforeunload表示当浏览器窗口关闭或者刷新时，会触发beforeunload事件
        window.addEventListener("beforeunload", () => {
            sessionStorage.setItem("store", JSON.stringify(this.$store.state))
        })
    },
}
</script>

<style lang="less">
.page-wrapper {
    grid-row-gap: 10px;
}
.lab-table > .table-before {
    > .table-toolbar > .table-toolbar-filter-column {
        margin-right: 18px;
    }
    > .table-tabs {
        overflow-y: hidden;
        overflow-x: auto;
        flex: .9 !important;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
        /*滚动条整体样式*/
        // width: 10px;
        height: 4px;
        // background: #fff;
    }
    .el-tabs__nav-wrap{
        margin-bottom: 0;
    }
    }
}
.current-row {
    background: #dbeaff;
}
.el-select .el-tag.el-tag--info {
    max-width: 70%;
}
.el-table__body tr.current-row > td.el-table__cell {
    background-color: unset;
}
.lab-drawer__body .el-select-dropdown {
    max-width: 370px !important;
}
.yellowColor:hover {
    color: rgb(255, 103, 0);
}
.el-tabs__nav-wrap {
    &::after {
        height: 1px !important;
        background-color: #d1d1d1;
    }
}
.el-radio {
    padding: 5px 0;
}
.el-table {
    border-radius: 3px !important;

    .el-button--text {
        color: #3370ff;
    }
}

.el-message-box__btns {
    display: flex !important;
    flex-flow: row-reverse nowrap;

    // justify-content: flex-end;
    & button {
        &:nth-child(2) {
            background-color: var(--main-bg-color);
            color: #fff !important;
            margin-left: 10px;
            margin-right: 10px;
        }

        &:nth-child(1) {
            background-color: #fff !important;
            border: 1px solid #dcdfe6 !important;
            color: #606266 !important;
        }
    }
}
.tableTitle {
    font-size: 20px;
    font-weight: bold;
}
.el-button--primary {
    // background-color: #0f7c4f !important;
    // border-color: #0f7c4f !important;;
}

.el-radio {
    .el-radio__input {
        &.is-checked {
            & > .el-radio__inner {
                background: #fff;
                border-color: var(--main-bg-color);

                &::after {
                    background-color: var(--main-bg-color);
                    width: 6px;
                    height: 6px;
                }
            }

            & + .el-radio__label {
                color: var(--main-bg-color);
            }
        }

        & > .el-radio__inner {
            &:hover {
                border-color: var(--main-bg-color);
            }
        }
    }
}

.custom-class {
    .el-dialog__body {
        padding-top: 10px !important;
    }

    .el-dialog__body {
        padding-left: 55px !important;
        padding-right: 55px !important;

        .is-required {
            margin-left: 0px !important;
        }

        .el-form-item {
            margin-left: 0px !important;
        }
    }

    .el-dialog__footer {
        padding-bottom: 25px !important;
        padding-right: 55px !important;
    }
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: var(--main-bg-color) !important;
    border-color: var(--main-bg-color) !important;
}

.el-checkbox {
    & > .el-checkbox__input {
        &.is-checked {
            & + .el-checkbox__label {
                color: var(--main-bg-color);
            }
        }

        & > .el-checkbox__inner {
            &:hover {
                border-color: var(--main-bg-color) !important;
            }
        }
    }
}

.el-select {
    & > .el-input {
        &.is-focus {
            & > .el-input__inner {
                border-color: var(--main-bg-color) !important;
            }
        }

        & > .el-input__inner {
            &:hover {
                border-color: var(--main-bg-color) !important;
            }
        }
    }
}

.el-select-dropdown {
    & li.el-select-dropdown__item {
        &.selected {
            color: var(--main-bg-color);
        }
    }
}

// .el-form-item.is-required > .el-form-item__label {
//     &::before {
//         content: " " !important;
//     }

//     &::after {
//         content: "*";
//         color: #f56c6c;
//         margin-left: 4px;
//     }
// }

// .el-form-item {
//     margin-left: 10px;
// }

.home-right {
    margin-left: 10px !important;
}

.center {
    /* margin-top: 10px !important; */
}

.twoCharts {
    width: calc(50% - 10px) !important;
    /* width: 49% !important; */
    margin-right: 10px !important;
}

html {
    height: 100%;
}

#app {
    overflow: hidden;
    position: relative;
    font-family: Microsoft YaHei !important;
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    .el-form-item__label:before {
        margin-right: 0 !important;
    }
    /* font-family: "PingFang SC", "Lantinghei SC", "Microsoft Yahei",
    "\5FAE\8F6F\96C5\9ED1", "helvetica", "arial", "verdana", "tahoma",
    "sans-serif"; */
}

body,
div,
section,
ul,
li,
a,
aside,
main,
p {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
}

/** 清除内外边距 **/
body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    blockquote,
    /* structural elements 结构元素 */
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    /* list elements 列表元素 */
    pre,
    /* text formatting elements 文本格式元素 */
    form,
    fieldset,
    legend,
    button,
    input,
    textarea,
    /* form elements 表单元素 */
    th,
    td

    /* table elements 表格元素 */ {
    margin: 0;
    padding: 0;
}

/** 设置默认字体 **/
body,
    button,
    input,
    select,
    textarea

    /* for ie */ {
    font: 14px/1.5 tahoma, \5b8b\4f53;
    font-family: Microsoft Yahei;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

address,
cite,
dfn,
em,
var {
    font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp {
    font-family: courier new, courier, monospace;
}

/* 统一等宽字体 */
small {
    font-size: 12px;
}

/* 小于 12px 的中文很难阅读, 让 small 正常化 */
/** 重置列表元素 **/
ul,
ol {
    list-style: none;
}

/** 重置文本格式元素 **/
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

sup {
    vertical-align: text-top;
}

/* 重置, 减少对行高的影响 */
sub {
    vertical-align: text-bottom;
}

/** 重置表单元素 **/
legend {
    color: #000;
}

/* for ie6 */
fieldset,
img {
    border: 0;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
    font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
/* 注：optgroup 无法扶正 */
/** 重置表格元素 **/
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* 重置 HTML5 元素 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    display: block;
    margin: 0;
    padding: 0;
}

mark {
    background: #ff0;
}

/* 设置placeholder的默认样式 */
:-moz-placeholder {
    color: #ddd;
    opacity: 1;
}

::-moz-placeholder {
    color: #ddd;
    opacity: 1;
}

input:-ms-input-placeholder {
    color: #ddd;
    opacity: 1;
}

input::-webkit-input-placeholder {
    color: #ddd;
    opacity: 1;
}

div {
    box-sizing: border-box;
}

& .el-button {
    &.is-disabled {
        opacity: 0.6;
    }

    &.internalButton {
        & > span {
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
                margin-right: 10px;
            }

            & > span {
                font-size: 14px;
            }
        }
    }
}

& .hover {
    &:hover {
        color: var(--main-bg-color) !important;
    }
}

& .cursor-pointer {
    cursor: pointer;
}

& .el-date-editor.el-input {
    width: 100% !important;
}

& .el-form-item__error {
    left: 0 !important;
}

& .el-radio {
    // &.is-checked{
    //   & .el-radio__label{
    //     color: red;
    //   }
    // }
}
.el-table__empty-text {
    align-self: flex-start;
    line-height: unset !important;
    padding: 40px 0 0;
}
.el-popover.el-popper {
    & > .el-popconfirm {
        padding: 10px;

        & > .el-popconfirm__main {
            justify-content: center;
        }

        & > .el-popconfirm__action {
            padding: 10px 0 0;

            & > button {
                color: #fff;
                background-color: var(--main-bg-color) !important;
                border: var(--main-bg-color) !important;
                padding: 7px 20px !important;
                font-size: 12px !important;
                border-radius: 3px !important;
            }
        }
    }
}

.el-tooltip__popper {
    background-color: var(--main-bg-color) !important;
    // margin-left: 20px;
}

.is-light {
    background: rgb(248, 248, 248) !important;
}

.el-loading-mask {
    opacity: 0.3;
}

/deep/.labMessageClass {
    .el-icon-close {
        right: 10px !important;
        top: 24px !important;
    }

    .el-button--primary {
        background-color: #00796a !important;
        border-color: #00796a !important;
    }

    .el-button--primary:hover {
        background-color: #00796a;
        border-color: #00796a;
    }
}
</style>

<style lang="less">
:root {
    --main-bg-color: rgb(51, 112, 255) !important;
    // --main-bg-color: red!important;
    .el-table .el-table__cell {
        padding: 9.5px 0;
    }
}
</style>
