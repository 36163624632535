import Vue from "vue"
import Vuex from "vuex"
import router from "@/router"
import api from "@/api/api"
import { setLocalToken, setWindowToken } from "@/core/token"
import { isElectron } from "@/core/Electron"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {
            id: "",
            userName: "",
            tenantId: "",
            mobileNumber: "",
            roleList: "",
            memberId: "",
            realName: "",
            isAdmin: "",
            orgName: "",
            orgId: "",
            /** 用户应用id */
            productIds: [],
        },
        activeIndex: "",
        NavigationIndex: null,
        isDev: process.env.NODE_ENV !== "production",
        userId: "",
        isLicense: "",

        /**用户导航及按钮权限 */
        productModuleTreeList: [],
        productModuleCode: [],
        /**路由 */
        routeMenu: [],
        // 字典
        dataDictionary: {
            // 归属表单
            formAttr: [
                {
                    label: "委托表单",
                    value: "01",
                },
                {
                    label: "任务表单",
                    value: "02",
                },
                {
                    label: "样品表单",
                    value: "03",
                },
            ],
            // 表单状态
            formStatus: [
                {
                    label: "发布",
                    value: "01",
                    style: {
                        color: "#058E44",
                    },
                },
                {
                    label: "作废",
                    value: "02",
                    style: {
                        color: "#D9001B",
                    },
                },
                {
                    label: "草稿",
                    value: "03",
                    style: {
                        color: "#E6D540",
                    },
                },
            ],
            /** 模板管理-模板库  模板类型 */
            templateType: [
                { label: "通用模板", value: "01" },
                { label: "项目模板", value: "02" },
                { label: "其他模板", value: "03" },
                { label: "文档结构", value: "04" },
            ],
            /** 模板管理-模板库  模板状态 */
            templateStatus: [
                { label: "发布", value: "01", style: { color: "#058E44" } },
                { label: "草稿", value: "02", style: { color: "#E6D540" } },
                { label: "作废", value: "03", style: { color: "#D9001B" } },
            ],
            /** 模板管理-模板库 换页方式 */
            pageBreakType: [
                { label: "单页", value: "01", svg: "page-single" },
                { label: "多页", value: "02", svg: "page-multi" },
            ],
            /** 模板管理-模板库 分隔符 */
            insertBreak: [
                { label: "分页符", value: "pageBreak" },
                { label: "分节符", value: "sectionBreak" },
            ],
        },
        /** 前台 后台 客户端 显示不同 title */
        documentTitle: "小乐报告平台",
        /** 前台 title */
        documentFrontTitle: "小乐报告平台",
        /** 后台 title */
        documentBackTitle: "小乐报告管理后台",
        /** 客户端 title */
        documentClientTitle: "小乐报告自动化工具",
        /** 窗口是否最大化 应用在Electron环境中 */
        isMaximized: false,
        /** 是否从统一认证过来 true为是 false为本系统登录 */
        isMentWeb: false,
    },
    mutations: {
        set_tenantId(state, tenantId) {
            state.tenantId = tenantId
        },
        setTokenId(state, id) {
            this.state.userId = id
        },
        setMentWeb(state, isMentWeb) {
            this.state.isMentWeb = isMentWeb
        },
        setMenuButton(state, MenuButton) {
            state.productModuleTreeList = MenuButton.productModuleTreeList
            state.productModuleCode = MenuButton.productModuleCode || []
            state.routeMenu = MenuButton.moduleUrlList || []
        },

        /**
         * @description 登录之后设置当前窗口和本地token
         */
        setToken(state, token) {
            console.log("commit.setToken", token)
            let Token = token
            if (token) {
                Token = "labbol " + token
            }
            // state.token = token;
            setWindowToken(Token)
            setLocalToken(Token)
        },
        setAdmin(state, data) {
            this.state.isAdmin = data
        },
        setUserInfo(state, userInfo) {
            let { token, ...params } = userInfo
            // let { id, isAdmin, tenantId } = params;
            state.userInfo = {
                ...(params || {}),
            }
        },
        setLicense(state, payload) {
            this.state.isLicense = payload
        },
        clearStorage() {
            sessionStorage.setItem("username", "")
            sessionStorage.setItem("password", "")
            sessionStorage.setItem("rememberPassword", false)
            sessionStorage.setItem("autoLogin", false)
        },
        resetState(state) {
            state.openTab = [
                {
                    name: "Home",
                    title: "工作台",
                    route: "/entrustedmanagement/home",
                    titleName: "",
                },
            ]
        },
        /** electron环境下 窗口最大化 或者 还原 */
        setIsMaximized(state, isMaximized) {
            // console.log('setIsMaximized', isMaximized);
            state.isMaximized = isMaximized
        },
        /** 设置前台 title 和 标题  在路由独享守卫中执行 */
        setDocumentFrontTitle(state) {
            if (isElectron) {
                state.documentTitle = state.documentClientTitle
            } else {
                state.documentTitle = state.documentFrontTitle
            }
            document.title = state.documentTitle
            console.log("setDocumentFrontTitle.beforeEnter", state.documentTitle)
        },

        /** 设置后台 title 和 标题  在路由独享守卫中执行 */
        setDocumentBackTitle(state) {
            if (isElectron) {
                state.documentTitle = state.documentClientTitle
            } else {
                state.documentTitle = state.documentBackTitle
            }
            document.title = state.documentTitle
            console.log("setDocumentBackTitle.beforeEnter", state.documentTitle)
        },
    },
    actions: {
        async getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                api.getLoginAPI({ productCode: "XiaoLeReport" }, "/tenant/getLoginUser")
                    .then(async res => {
                        // commit("getUserModule", res.data.data.productIds)
                        commit("setUserInfo", res.data.data)
                        resolve()
                    })
                    .catch(error => {
                        console.log("error", error)
                        resolve()
                    })
            })
        },
        /**获取用户权限 */
        async getUserModule({ commit }) {
            return new Promise((resolve, reject) => {
                api.postLoginAPI(
                    {
                        userProductIds: this.state.userInfo.productIds,
                    },
                    "/tenant/queryLoginUserModule"
                )
                    .then(res => {
                        commit("setMenuButton",res.data.data)
                        resolve()
                    })
                    .catch(error => {
                        console.log("error", error)
                        resolve()
                    })
            })
        },
        async logout({ commit }) {
            return new Promise((resolve, reject) => {
                if (this.state.isMentWeb) {
                    commit("setToken", "")
                    commit("resetState")
                    router.push({
                        path: "/",
                    })
                } else {
                    api.getLoginAPI("", "/sso/signout", "", {
                        showSuccessMessage: true,
                    })
                        .then(() => {
                            commit("setToken", "")
                            commit("resetState")
                            router.push({
                                path: "/",
                            })
                        })
                        .catch(error => {})
                }
            })
        },
    },
    modules: {},
})
