/****   http.js   ****/
// 导入封装好的axios实例
import request from "./request"

// 直接导入api
const http = {
    /**
     * methods: 请求
     * @param url 请求地址
     * @param params 请求参数
     */
    get(params, url, getConfig) {
        const config = {
            method: "get",
            url: url,
            ...getConfig,
        }
        if (params) config.params = params
        return request(config)
    },
    post(params, url, urlData, customerConfig) {
        const config = {
            method: "post",
            url: url,
            ...(customerConfig || {}),
        }
        if (urlData) config.params = urlData
        config.data = params
        return request(config)
    },
    postFormData(params, url, urlData, headers) {
        const config = {
            method: "post",
            url: url,
            headers: {
                "Content-Type": "multipart/form-data",
                // 'responseType': 'blob',
                ...headers,
            },
        }
        if (urlData) config.params = urlData
        config.data = params
        return request(config)
    },
}
//导出
export default http
