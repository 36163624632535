import Form from './src/BasicForm.vue';

export default Form;

/**
 * @param {FormConfig} formConfig
 * @description 表单配置项
*/
const useForm = (formConfig) => {

}
useForm({
	labelPosition: 'left',
	schemas: [
		{
			component: 'InputNumber',
			componentProps: {
			
			}
		}
	]
})

export {
	useForm
}