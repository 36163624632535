import { isValidValueNumber } from "./utils";
const useColProp = (schema) => {
    let { col } = schema;
    col = col || { span: 8 };
    // let style = {};
    // // console.log('useColProp', col, isValidValueNumber(span) && span);
    // if (isValidValueNumber(span) && span){
    //     // style['width'] = span / 24 * 100 + '%';
    //     style['width'] = span / 24 * 100 + '%';
    // }
    return col;
};

export {
    useColProp
}