<template>
    <div :class="getWrapperClass">
        <!-- <BasicForm v-if="useSearchForm" ref="FormRef" v-bind="getFormProps" @search="search" @reset="reset" /> -->
        <div class="table-before" ref="TableBeforeRef">
            <div class="table-before-left">
                <slot name="table-before-left"></slot>
            </div>
            <div class="table-before-right">
                <slot name="table-before-right"></slot>
            </div>
        </div>
        <div :class="getClass + '__body'">
            <Table v-bind="getTableProps" :data="innerData" :empty-text="emptyText" ref="TableRef" @select="select"
                @select-all="selectAll" @selection-change="selectionChange" @row-click="rowClick">
                <template v-for="(column, index) in getColumns">
                    <template v-if="hasScopeSlot(column)">
                        <TableColumn :key="index" v-bind="column">
                            <template slot="header" slot-scope="scope">
                                <slot :name="column.headerSlot || (column.prop + '-header')" v-bind="scope">
                                    {{ column.label }}
                                    <img v-if="column.useDefaultHeader" src="@/assets/sebot.png" alt="" />
                                </slot>
                            </template>
                            <template slot-scope="scope">
                                <slot :name="column.slot || column.prop" v-bind="scope">{{ column.label }}</slot>
                            </template>
                        </TableColumn>
                    </template>
                    <TableColumn v-else-if="column.options" :key="index" v-bind="column">
                        <template slot="header" slot-scope="scope">
                            <slot :name="column.headerSlot || (column.prop + '-header')" v-bind="scope">
                                {{ column.label }}
                                <img v-if="column.useDefaultHeader" src="@/assets/sebot.png" alt="" />
                            </slot>
                        </template>
                        <template slot-scope="scope">
                            <div v-if="getComputedValueDotStyle(scope.row, column)"
                                style="display: flex; align-items: center;">
                                <span v-bind="getComputedValueDotStyle(scope.row, column)"></span>
                                <span v-bind="getComputedValueAttrs(scope.row, column)">{{ getComputedValue(scope.row,
                                    column)
                                }}</span>
                            </div>
                            <span v-else v-bind="getComputedValueAttrs(scope.row, column)">{{ getComputedValue(scope.row,
                                column)
                            }}</span>
                        </template>
                    </TableColumn>
                    <TableColumn v-else :key="index" v-bind="column">
                        <template slot="header" slot-scope="scope">
                            <slot :name="column.headerSlot || (column.prop + '-header')" v-bind="scope">
                                {{ column.label }}
                                <img v-if="column.useDefaultHeader" src="@/assets/sebot.png" alt="" />
                            </slot>
                        </template>
                    </TableColumn>
                </template>
            </Table>
            <!-- <div v-show="loadingData" class="loading-mask" :style="loadingMaskStyle">
                数据正在装载中......
            </div> -->
        </div>
        <Pagination v-if="pagination" v-bind="pagination" @size-change="sizeChange" @current-change="currentChange" />
    </div>
</template>

<script>
    import {
        Table,
        TableColumn
    } from 'element-ui';
    // import BasicForm from '../../Form/index';
    import BasicTableColumn from './BasicTableColumn.vue';
    import Pagination from '@/components/BasicPagination';
    import {
        getUuid
    } from '@/utils/utils';
    import {
        prefixCls
    } from './constant';
    import {
        getFormProps,
        getTableProps,
        getTableColumnProps
    } from './hooks';
    export default {
        name: 'LabTable',
        components: {
            // BasicForm
            Table,
            TableColumn,
            BasicTableColumn,
            Pagination,
        },
        props: {
            loading: {
                type: Boolean,
                default: true,
            },
            data: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            columns: {
                type: Array,
                default: () => {
                    return []
                }
            },
            useSearchForm: {
                type: Boolean,
                default: false,
            },
            model: {
                type: Object
            },
            formConfig: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            pagination: {
                type: Boolean | Object,
                default: false,
            },
        },
        data() {
            return {
                onResizeTimeout: null,
                headerDragendTimeout: null,
                uuid: getUuid(32, 36),
                innerData: [],
            }
        },
        mounted() {
        },
        computed: {
            getWrapperClass() {
                return [prefixCls, 'wrapper_' + this.uuid];
            },
            getClass() {
                return prefixCls;
            },
            // hooks
            getFormProps() {
                return getFormProps(this.formConfig);
            },
            getTableProps() {
                return getTableProps(this.$attrs);
            },
            getColumns() {
                // return this.columns;
                return this.columns.filter(item => typeof item.ifShow === 'undefined' || !!item.ifShow).map(item =>
                    getTableColumnProps(item));
            },
            slotKeys() {
                return Object.keys(this.$slots);
            },
            scopeSlotKeys() {
                return Object.keys(this.$scopedSlots);
            },
            emptyText() {
                if (this.loading) {
                    return '数据正在装载中......';
                } else if (!Array.isArray(this.data) || !this.data.length) {
                    return '暂无数据'
                } else {
                    return '数据正在装载中......'
                }
            }
        },
        watch: {
            emptyText: {
                handler(value) {
                    // console.log('watch.emptyText', value);
                },
                immediate: true,
            },
            innerData: {
                handler(value) {
                    // console.log('watch.innerData', value);
                },
                immediate: true,
            },
            loading: {
                handler(value) {
                    if (value) {
                        this.innerData = []
                    }
                },
                immediate: true
            },
            data: {
                handler(data) {
                    // if (Array.isArray(data) && data.length){
                    //     setTimeout(() => {
                    //         this.innerData = data;
                    //         // console.log('watch.data', data);
                    //     }, 200)
                    //     return;
                    // }
                    this.innerData = data;
                },
                immediate: true
            },
        },
        methods: {
            // 是否有scopeSlot插槽
            hasScopeSlot(column) {
                let {
                    prop,
                    slot,
                    type
                } = column;
                let flag = (!type || !['selection', 'index'].includes(type)) && (this.scopeSlotKeys.includes(prop) ||
                    this.scopeSlotKeys.includes(slot) ||
                    this.slotKeys.includes(prop) || this.slotKeys.includes(slot));
                // console.log('hasScopeSlot', prop, flag);
                return flag;
            },
            // 回调
            select(selection, row) {
                this.$emit('select', selection, row);
            },
            selectAll(selection) {
                this.$emit('select-all', selection);
            },
            selectionChange(selection) {
                this.$emit('selection-change', selection);
            },
            rowClick(row, column, event) {
                this.$emit('row-click', row, column, event);
            },
            sizeChange(pageSize) {
                // console.log('sizeChange', pageSize);
                this.$emit('size-change', pageSize);
            },
            currentChange(pageNum) {
                // console.log('currentChange', pageNum);
                this.$emit('current-change', pageNum);
            },
            // 事件
            clearSelection() {
                this.$refs.TableRef.clearSelection();
            },
            toggleRowSelection(row, selected) {
                this.$refs.TableRef.toggleRowSelection(row, selected);
            },
            toggleAllSelection() {
                this.$refs.TableRef.toggleAllSelection();
            },
            toggleRowExpansion(row, expanded) {
                this.$refs.TableRef.clearSelection(row, expanded);
            },
            setCurrentRow(row) {
                this.$refs.TableRef.setCurrentRow(row);
            },
            clearSort() {
                this.$refs.TableRef.clearSort();
            },
            clearFilter(columnKey) {
                this.$refs.TableRef.clearFilter(columnKey);
            },
            doLayout() {
                this.$refs.TableRef.doLayout();
            },
            sort(prop, order) {
                this.$refs.TableRef.sort(prop, order);
            },
            // 搜索表单
            search() {
                // console.log('search');
                this.$emit('search');
            },
            reset() {
                // console.log('reset',);
                this.$emit('reset');
            },
            getComputedValue(row, column) {
                // console.log('getComputedValue', row);
                let {
                    options,
                    optionProps,
                    prop
                } = column;
                optionProps = optionProps || {
                    label: 'label',
                    value: 'value'
                };
                let find = options.find(item => item[optionProps.value] === row[prop]);
                if (find) {
                    return find[optionProps.label]
                } else {
                    return row[prop]
                }
            },
            getComputedValueAttrs(row, column) {
                let {
                    options,
                    optionProps,
                    prop
                } = column;
                optionProps = optionProps || {
                    label: 'label',
                    value: 'value'
                };
                let find = options.find(item => item[optionProps.value] === row[prop]);
                if (find && find.style) {
                    return find;
                } else {
                    return {}
                }
            },
            getComputedValueDotStyle(row, column) {
                let {
                    options,
                    optionProps,
                    prop
                } = column;
                optionProps = optionProps || {
                    label: 'label',
                    value: 'value'
                };
                let find = options.find(item => item[optionProps.value] === row[prop]);
                if (find && find.dot) {
                    let style = {
                        width: '10px',
                        height: '10px',
                        display: 'inline-block',
                        'borderRadius': '50%',
                        'marginRight': '10px',
                        ...find.style ? {
                            backgroundColor: find.style.color || ''
                        } : {},
                        ...typeof find.dot === 'object' ? {
                            ...find.dot.color ? {
                                backgroundColor: find.dot.color
                            } : {},
                            ...find.dot
                        } : {},
                    };
                    // console.log('find.dot', find.dot, style);
                    return {
                        style
                    };
                } else {
                    return false
                }
            },
        }
    }
</script>

<style lang="less">
    @import url('./index.less');
</style>