import { componentsMap, componentsDefaultStyle, componentsDefaultProps } from "../../constant"
import { actionButtonTypes } from './constant';
import { useColProp } from "../../hooks";
import { getUuid } from "../../utils";
import { prefixCls } from './constant';
import { PATTERNS } from "./rules.pattern";

/**
 * Schema 配置项规则
 * @typedef {Object} Schema
 * @property {number} span   Col Attributes 栅格占据的列数
 * @property {string} label   Form-Item Attributes 标签文本
 * @property {String} labelWidth  Form-Item Attributes 表单域标签的的宽度
 * @property {Boolean} required  Form-Item Attributes 是否必填
 */

/**
 * @param {Schema} schema 配置项规则
 * @description 基础组件名称映射
*/
const useComponentName = (schema) => {
    let { component, componentProps } = schema;
    // componentProps.
    // console.log('useComponentName', schema, component, componentsMap[component]);
    return componentsMap[component] || component || 'Input';
}
/**
 * @param {Schema} schema 配置项规则
 * @description 获取表单项初始值
*/
const getDefaultValue = (schema) => {

}
const useFormProp = (formConfig) => {
    let { labelWidth, schemas } = formConfig;
    console.log('useFormProp', labelWidth);
    if (labelWidth && !(String(labelWidth).includes('px'))){
        labelWidth += 'px';
        console.log('useFormProp.labelWidth', labelWidth);
    }
    let model = {};
    schemas = schemas || [];
    for (let i = 0; i < schemas.length; i++){
        let { prop, component, componentProps } = schemas[i];
    }
    let config = {
        labelWidth: '80px',
        labelPosition: 'right',
        size: 'small',
        ...formConfig,
        ...labelWidth ? { labelWidth } : {},
    };
    
    delete config.schemas;
    delete config.actionColOptions;
    console.log('useFormProp', config);
    return config;
}
const useFormItemProp = (schema, $props) => {
    let { prop, component, labelWidth, colProps, style, class: itemClass, rules } = schema;
    let { labelPosition, labelSuffix, size } = $props;
    // :labelPosition="labelPosition" :labelSuffix="labelSuffix"
    let appendClass = [`${prefixCls}-item`, `${prefixCls}-item-${ useComponentName(schema) }`];
    if (Array.isArray(itemClass)){
        itemClass = itemClass.join(' ');
        // itemClass.push(`${prefixCls}-item`);
        // itemClass.push(`${prefixCls}-item-${ useComponentName(schema) }`);
    }
    itemClass = (itemClass || '') + (itemClass ? ' ' :'') + appendClass.join(' ');
    rules = rules || [];
    // if (component === 'InputNumber'){
    //     rules = [...rules, { type: 'number' }]
    // }
    let isLog = false;
    // let isLog = schema.label === '手机';
    isLog && console.log('useFormItemProp', rules);
    /**
     * @param {Object} rule 单个校验规则
     * @param {String|undefined} rule.type 类型
     * @param {String|undefined} rule.message 提示信息
     * @description 处理单个校验规则，进行格式化
     */
    let getRule = (rule) => {
        let { type, message } = rule;
        if (typeof type === 'undefined') return [ rule ];
        type = type.replace(/[^a-z]/ig, '').toUpperCase();
        if (typeof PATTERNS[type] === 'undefined') return [ rule ];
        rule['pattern'] = PATTERNS[type];
        delete rule.type;
        isLog && console.log('getRule', type, rule, PATTERNS[type]);
        if (typeof message === 'undefined'){
            rule['message'] = `请输入正确的${ schema.label || '' }`;
        }
        return [
            rule
        ]
    }
    let cookiedRules = [];
    for (let i = 0; i < rules.length; i++){
        // rules
        isLog && console.log(`rules[${i}]`, rules[i]);
        cookiedRules.push(...getRule(rules[i]));
    }
    isLog && console.log('getRule.cookiedRules', cookiedRules);
    
    return {
        labelPosition, labelSuffix,
        ...schema,
        rules: cookiedRules,
        size,
        class: itemClass,
        style: { ...style || {}, },
    };
}
const useComponentProps = (componentProps, schema) => {
    componentProps = componentProps || {};
    let { component, label } = schema;
    let isLogComponents = ['CheckboxGroup'];
    let isLog = isLogComponents.includes(component);
    let defaultProps = componentsDefaultProps[component] || {};
    if (isLog){
        console.log('useComponentProps', component, defaultProps, schema);
    }
    let keys = Object.keys(defaultProps);
    let reg = /^([^=]+)=([^=]+)$/;
    for (let i = 0; i < keys.length; i++){
        let execArray = reg.exec(keys[i]);
        if (execArray && componentProps[execArray[1]] == execArray[2]){
            componentProps = {
                ...defaultProps[keys[i]] || {},
                ...componentProps
            }
        }
    }
    if (/input/i.test(component)){
        componentProps = {
            type: 'text',
            style: componentsDefaultStyle[component] || {},
            ...componentProps,
        }
    }
    componentProps = {
        style: componentsDefaultStyle[component] || {},
        ...componentProps,
    }
    if (useComponentName(schema) === 'ItemDivider'){
        // console.log('ItemDivider', schema);
        componentProps = {
            label,
            ...componentProps,
        }
    }
    isLog && console.log('useComponentProps', componentProps);
    return componentProps;
}
const useFormColProp = (formConfig) => {
    let { formColOptions } = formConfig;
    if (useActionColProp(formConfig)){
        return {
            span: 20,
            md: {
                span: 18,
            },
            lg: {
                span: 20,
            },
            ...formColOptions ? formColOptions.colProps || {} : {},
        };
    } else {
        return {
            span: 24
        }
    }
    
}
const useActionColProp = (formConfig) => {
    
    let { actionColOptions } = formConfig;
    actionColOptions = actionColOptions || {}; 
    // console.log('useActionColProp', actionColOptions);
    let {
        showActionGroup,
        colProps, style,
        buttonGroupLayout,
        searchButtonOptions,
        resetButtonOptions,
        submitButtonOptions,
        cancelButtonOptions
    } = actionColOptions || {};
    if (typeof showActionGroup === 'boolean' && !showActionGroup) return false;
    
    colProps = {
        span: 4,
        md: {
            span: 6,
        },
        lg: {
            span: 4,
        },
        ...colProps || {},
        
    }
    // console.log('useActionColProp-before', actionColOptions, colProps);
    // if (showSearchButton){
        actionColOptions['searchButtonOptions'] = {
            text: '查询',
            type: 'primary',
            name: 'search',
            ...searchButtonOptions || {}
        }
    // }
    // if (showResetButton){
        actionColOptions['resetButtonOptions'] = {
            text: '重置',
            type: 'primary',
            name: 'reset',
            ...resetButtonOptions || {}
        }
    // }
    // if (showSubmitButton){
        actionColOptions['submitButtonOptions'] = {
            text: '提交',
            type: 'primary',
            name: 'submit',
            ...submitButtonOptions || {}
        }
    // }
    // if (showCancelButton){
        actionColOptions['cancelButtonOptions'] = {
            text: '取消',
            type: 'primary',
            name: 'cancel',
            ...cancelButtonOptions || {}
        }
    // }
    if (!buttonGroupLayout){
        buttonGroupLayout = ['search', 'reset'];
    } else if (typeof buttonGroupLayout === 'string'){
        buttonGroupLayout = buttonGroupLayout.split(',');
    }
    let buttonOptions = [];
    for (let i = 0; i < buttonGroupLayout.length; i++){
        if (!actionButtonTypes.includes(buttonGroupLayout[i])) continue;
        // console.log(i, actionColOptions, `${ buttonGroupLayout[i] }ButtonOptions`);
        buttonOptions.push(
            {
                key: getUuid(32, 36),
                ...actionColOptions[`${ buttonGroupLayout[i] }ButtonOptions`],
            }
        )
    }
    // console.log('useActionColProp-after', actionColOptions, style);
    return buttonOptions.length ? { label: '', ...colProps, style, buttonOptions } : false;
}

const useFormItemColProp = (schema, { useSearchForm, colProps: baseColProps }) => {
    let { colProps, component } = schema;
    let props = {};
    if (useComponentName(schema) === 'ItemDivider'){
        props = {
            ...baseColProps,
            ...colProps,
            span: 24,
            style: {
                ...baseColProps ? baseColProps.style || {} : {},
                ...colProps ? colProps.style || {} : {}
            },
        }
    }
    else {
        props = {
            span: 6,
            // ...useSearchForm ? {} : { offset: 1 },
            // lg: {
            //     span: 6,
            // },
            // md: {
            //     span: 10,
            // },
            ...baseColProps || colProps || {},
            style: {
                ...baseColProps ? baseColProps.style || {} : {},
                ...colProps ? colProps.style || {} : {}
            },

        }
    }
    // console.log('useFormItemColProp', props);
    return props;
    
};
const useFormItemRowProp = (schema, { useSearchForm, rowProps: baseRowProps }) => {
    let { rowProps, component } = schema;
    let props = {};
    if (useComponentName(schema) === 'ItemDivider'){
        props = {
            ...baseRowProps,
            ...rowProps,
            style: {
                ...baseRowProps ? baseRowProps.style || {} : {},
                ...rowProps ? rowProps.style || {} : {}
            },
        }
    }
    else {
        props = {
            // ...useSearchForm ? {} : { offset: 1 },
            // lg: {
            //     span: 6,
            // },
            // md: {
            //     span: 10,
            // },
            ...baseRowProps || rowProps || {},
            style: {
                ...baseRowProps ? baseRowProps.style || {} : {},
                ...rowProps ? rowProps.style || {} : {}
            },

        }
    }
    // console.log('useFormItemColProp', props);
    return props;
    
};

export {
    useComponentName,
    useFormProp,
    useFormItemProp,
    useComponentProps,
    useFormColProp,
    useActionColProp,
    useFormItemColProp,
    useFormItemRowProp
}