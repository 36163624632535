<template>
    <el-select class="Tree_select" v-model="valueId" :disabled="disabled" :popper-append-to-body="false" :clearable="clearable" @clear="clearHandle">
        <el-input v-if="ShowInput" class="selectInput" :placeholder="placeholder" v-model="filterText"></el-input>
        <el-option :value="valueId" :label="valueTitle" class="options">
            <el-tree
                id="tree-option"
                ref="selectTree"
                :accordion="accordion"
                :data="options"
                :props="props"
                :node-key="props.value"
                :default-expanded-keys="defaultExpandedKey"
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
            >
                <template v-slot="{ node }">
                    <slot name="itemNode" :node="node" :data="node.data">
                        <div class="item_node" :title="node.data[props.label]">
                             <svg-icon v-if="node.data.icon" :icon-class="node.data.icon"></svg-icon>
                            {{ node.data[props.label] }}
                        </div>
                    </slot>
                </template>
            </el-tree>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "el-tree-select",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        /** 配置项 */
        props: {
            type: Object,
            default: () => {
                return {
                    value: "groupId", // ID字段名
                    label: "groupName", // 显示名称
                    children: "children", // 子级字段名
                }
            },
        },
        /** 选项列表数据(树形结构的对象数组) */
        options: {
            type: Array,
            default: () => {
                return []
            },
        },
        /** 不展示全部(父节点) */
        isParent: {
            type: Boolean,
            default: false,
        },
        /** 是否展示筛选输入 */
        ShowInput: {
            type: Boolean,
            default: true,
        },
        /** 是否展示筛选输入 */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** 节点可选 */
        showNode: {
            type: Boolean,
            default: true,
        },
        /** 初始值 */
        value: {
            type: String,
            default: () => {
                return ""
            },
        },
        /** 可清空选项 */
        clearable: {
            type: Boolean,
            default: () => {
                return true
            },
        },
        /** 自动收起 */
        accordion: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        placeholder: {
            type: String,
            default: () => {
                return "检索关键字"
            },
        },
    },
    data() {
        return {
            filterText: "",
            valueId: this.value, // 初始值
            valueTitle: "",
            defaultExpandedKey: [],
            ParentAndSon: [],
        }
    },
    mounted() {
        this.initHandle()
    },
    methods: {
        // 初始化值
        initHandle() {
            if (this.valueId) {
                console.log("safsdf",this.LookItem(this.valueId));
                this.valueTitle = this.isParent ? this.LookLabel(this.valueId).slice(-1)[0] : this.LookLabel(this.valueId).join("/") // 初始化显示
                this.$refs.selectTree.setCurrentKey(this.valueId) // 设置默认选中
                this.defaultExpandedKey = [this.valueId] // 设置默认展开
            }
            this.initScroll()
        },
        // 初始化滚动条
        initScroll() {
            this.$nextTick(() => {
                let scrollWrap = document.querySelectorAll(".el-scrollbar .el-select-dropdown__wrap")[0]
                let scrollBar = document.querySelectorAll(".el-scrollbar .el-scrollbar__bar")
                scrollWrap.style.cssText = "margin: 0px; max-height: none; overflow: hidden;"
                scrollBar.forEach(ele => (ele.style.width = 0))
            })
        },
        //展开树
        initArray(arr, id) {
            let val = []
            if (Array.isArray(arr) && arr.length) {
                arr.forEach(ele => {
                    if (Array.isArray(ele[this.props.children]) && ele[this.props.children].length) {
                        val.push({
                            ...ele,
                            label: ele[this.props.label],
                            value: ele[this.props.value],
                            parent: id || null,
                        })
                        val.push(...this.initArray(ele[this.props.children], ele[this.props.value]))
                    } else {
                        val.push({
                            ...ele,
                            label: ele[this.props.label],
                            value: ele[this.props.value],
                            parent: id || null,
                        })
                    }
                })
            }
            return val
        },
        //寻找
        LookLabel(item) {
            let arr = []
            let obj = {}
            if (this.ParentAndSon.length) {
                obj = this.ParentAndSon.filter(ele => ele.value == item)[0]
                obj && arr.unshift(obj.label)
                if (obj && obj.parent) {
                    arr.unshift(...this.LookLabel(obj.parent))
                }
            }
            return arr
        },
        //寻找
        LookItem(item) {
            let arr = []
            let obj = {}
            if (this.ParentAndSon.length) {
                obj = this.ParentAndSon.filter(ele => ele.value == item)[0]
                obj && arr.unshift(obj)
                if (obj && obj.parent) {
                    arr.unshift(...this.LookItem(obj.parent))
                }
            }
            return arr
        },
        // 切换选项
        handleNodeClick(node) {
            console.log(node)
            if (!this.showNode && node.children && node.children.length) {
                return
            } else {
                this.valueTitle = this.isParent ? this.LookLabel(this.valueId).slice(-1)[0] : this.LookLabel(node[this.props.value]).join("/")
                this.valueId = node[this.props.value]
                this.$emit("change", this.valueId, { label: node[this.props.label], value: node[this.props.value] })
                this.defaultExpandedKey = []
            }
        },

        // 清除选中
        clearHandle() {
            this.valueTitle = ""
            this.valueId = null
            this.defaultExpandedKey = []
            this.clearSelected()
            this.$emit("change", this.valueId)
        },
        /* 清空选中样式 */
        clearSelected() {
            let allNode = document.querySelectorAll("#tree-option .el-tree-node")
            allNode.forEach(element => element.classList.remove("is-current"))
        },
        filterNode(value, data) {
            if (!value) return true
            return data[this.props.label].indexOf(value) !== -1
        },
    },
    watch: {
        value() {
            this.valueId = this.value
            this.initHandle()
        },
        options: {
            handler(val) {
                if (val.length) {
                    this.ParentAndSon = this.initArray(val)
                }
            },
            immediate: true,
        },
        filterText(val) {
            this.$refs.selectTree.filter(val)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    max-height: 274px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
}

.el-select-dropdown__item.selected {
    font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
}
.el-tree-node__label {
    font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
    color: #409eff;
    font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
}
.selectInput {
    padding: 0 5px;
    box-sizing: border-box;
}
</style>
<style lang="less">
.Tree_select {
    .item_node {
        width: calc(100% - 24px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .el-select-dropdown .el-select-dropdown__list {
        width: 100%;
        overflow: hidden;
    }
}
</style>
