import Vue from "vue"
import SvgIcon from "@/components/SvgIcon" // svg component

// register globally
Vue.component("svg-icon", SvgIcon)

const req = require.context("./svg", false, /\.svg$/);
const formDesignReq = require.context("./form-design-svg", false, /\.svg$/);
const dataSetReq = require.context("./data-set-svg", false, /\.svg$/); //数据集图标
const templateReq = require.context("./template-svg", true, /\.svg$/); //模板库图标
const offlineReq = require.context("./offline-svg", true, /\.svg$/); //网络未连接图标

const pageBackReq = require.context("./page-back-svg", true, /\.svg$/); //头部图标 通用组件PageWrapper中PageBack组件

const basicComponents = require.context("./basic-components", true, /\.svg$/); //basic-components 基础组件库中的svg图标
const reportComponents = require.context("./report-components", true, /\.svg$/); //report-components 报告生成池的svg图标

const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req);
requireAll(formDesignReq);
requireAll(dataSetReq);
requireAll(templateReq);
requireAll(pageBackReq);
requireAll(offlineReq);
requireAll(basicComponents);
requireAll(reportComponents);


