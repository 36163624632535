<template>
    <div id="filereport">
        <Dialog
            :fullscreen="isFullScreen"
            :close-on-click-modal="false"
            :showClose="false"
            :custom-class="isFullScreen ? 'filereport-class' : 'firle filereport-class'"
            :title="title"
            :visible.sync="visible"
            @close="close"
            :modal-append-to-body="false"
        >
            <template slot="title">
                <div @dblclick="isFullScreen = !isFullScreen">
                    <span>{{ title }}</span>
                    <div class="right_drag">
                        <span @click="isFullScreen = !isFullScreen">
                            <svg-icon style="font-size: 16px" :icon-class="isFullScreen ? 'quee' : 'queen'"></svg-icon>
                        </span>
                        <span @click="close">
                            <img src="@/assets/img/modal/deletedig.png" alt="" />
                        </span>
                    </div>
                </div>
            </template>
            <div class="file_body_task">
                <div class="file_left">
                    <BasicTable
                        row-key="id"
                        :loading="loading"
                        highlight-current-row
                        :currentRowKey="currentRowKey"
                        :data="FilesData"
                        :pagination="pagination"
                        :columns="columns.filter(ele => ele.ifShow)"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        @row-click="handleClickFileRow"
                    >
                        <template slot="table-before-left">
                            <Tabs v-model="activeFile" @tab-click="handleFileClick">
                                <TabPane v-for="(item, index) in menuList" :key="index" :label="item.title" :name="item.name"></TabPane>
                            </Tabs>
                        </template>
                        <template slot="table-before-right">
                            <el-button class="file_refresh" type="text" @click="handleClick">
                                <svg-icon icon-class="refresh" />
                                <!-- <svg-icon  :icon-class="item.svg"></svg-icon> -->
                                <span>刷新</span>
                            </el-button>
                            <div class="edit-column" @click="handleColumnEdit">
                                <img :src="tl" alt="" />
                                编辑列
                            </div>
                            <div class="filter-column">
                                <img :src="sx" alt="" />
                                编辑筛选器
                            </div>
                            <el-input v-model="keyWord" size="small" class="default-input" placeholder="按关键字搜索" @keyup.enter.native="handleSearch" />
                        </template>
                        <template slot="word">
                            <img src="../../assets/images/word.png" height="15" alt="" />
                        </template>
                        <template slot="genType" slot-scope="scope">
                            <!-- <div v-if="currentIng==scope.row.id&&State == '02'" class="parent_bar"> -->
                            <div>
                                <div v-if="scope.row.genType == '01' && !scope.row.timeConsumed" class="parent_bar">
                                    生成中
                                    <i class="el-icon-loading"></i>
                                </div>
                                <div v-else class="parent_bar">
                                    <div
                                        :class="[
                                            'origin-state',
                                            scope.row.genType === '01' ? 'color-green' : scope.row.genType === '02' ? 'color-green' : scope.row.genType === '03' ? 'color-red' : '',
                                        ]"
                                    >
                                        {{ scope.row.genType === "01" ? "正在生成" : scope.row.genType === "02" ? "生成成功" : scope.row.genType === "03" ? "生成失败" : "--" }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template slot="action" slot-scope="scope">
                            <el-button size="small" type="text" :disabled="!scope.row.fileSize" @click="handleOriginOrReportView(scope.row)">下载文件</el-button>
                            <el-button size="small" type="text" @click="openTemplate(scope.row)">打开报告库</el-button>
                            <el-button size="small" type="text" @click="UpNect(scope.row)">上传云</el-button>
                        </template>
                    </BasicTable>
                </div>
                <div class="file_right" :style="{ height: RightHeight + 'px' }">
                    <div class="table_header">
                        生成详情
                        <span title="清除" @click="ClearActivity"><svg-icon icon-class="header-delete" color="#000" size="16px"></svg-icon></span>
                    </div>
                    <div ref="bodyContral" class="table_body">
                        <Timeline>
                            <TimelineItem
                                v-for="(activity, index) in activities"
                                :title="activity.content"
                                :key="index"
                                :type="activity.type"
                                :color="activity.color"
                                :timestamp="activity.timestamp"
                                placement="right"
                            >
                                {{ activity.timestamp }}
                                {{ activity.content }}
                            </TimelineItem>
                        </Timeline>
                    </div>
                </div>
            </div>
        </Dialog>
        <!-- 编辑列 抽屉 -->
        <BasicDrawer
            class="columnDrawer"
            :visible="columnDrawerVisble"
            :title="columnDrawerTitle"
            :closeOnCancel="false"
            @confirm="handleDrawerConfirm"
            @cancel="handleDrawerCancel"
            @close="handleDrawerClose"
        >
            <div class="column-header">
                <div :class="['column-add', columnDrawerAddDisabled ? 'is-disabled' : '']" @click="handleColumnAdd">
                    <svg-icon icon-class="add"></svg-icon>
                    添加列
                </div>
                <div class="column-reset" @click="handleColumnReset">
                    <i class="el-icon-refresh-right"></i>
                    重置
                </div>
            </div>
            <div class="column-body">
                <template v-for="(item, index) in innerColumns">
                    <div v-if="getColumnShowStatusInDrawer(item)" class="column-item" :key="index" @mouseenter="handleDrawerColumnHover(item)" @mouseleave="handleDrawerColumnLeave(item)">
                        <div class="column-item--checkbox">
                            <el-checkbox v-if="!columnShowState" v-model="item.checked"></el-checkbox>
                        </div>
                        <div class="column-item--label">{{ item.label }}</div>
                        <el-dropdown
                            placement="bottom"
                            trigger="click"
                            size="small"
                            @command="command => handleDrawerColumnDropdownCommand(item, command)"
                            @visible-change="visible => handleDrawerColumnDropdownVisibleChange(visible, item)"
                        >
                            <span v-show="columnShowState && item.dropdownLinkVisible" class="el-dropdown-link">···</span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="delete">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </div>
        </BasicDrawer>
    </div>
</template>
<script>
import router from "@/router"
import { WebsocketMessage } from "@/assets/js/Global"
import { getWindowToken } from "@/core/token"
import { queryPageReportPool, getFileUrl } from "@/api/main"
import { Timeline, Dialog, Button, MessageBox, Menu, Tabs, TimelineItem, TabPane, MenuItem, Input, DropdownItem, DropdownMenu, Checkbox, Dropdown } from "element-ui"
import tl from "@/assets/tl.png"
import sx from "@/assets/sx.png"
import SvgIcon from "@/components/SvgIcon"
import BasicDrawer from "@/components/BasicDrawer"
import BasicTable from "@/components/BasicTable"
export default {
    name: "choiceObject",
    components: {
        Timeline,
        Dialog,
        BasicDrawer,
        "el-menu": Menu,
        "el-menu-item": MenuItem,
        "el-button": Button,
        "el-input": Input,
        "el-dropdown-menu": DropdownMenu,
        "el-dropdown": Dropdown,
        "el-dropdown-item": DropdownItem,
        "el-checkbox": Checkbox,
        "svg-icon": SvgIcon,
        Tabs,
        TabPane,
        BasicTable,
        TimelineItem,
    },
    data() {
        return {
            tl,
            sx,
            activeFile: "00",
            keyWord: "",
            RightHeight: "300",
            title: "报告生成池",
            visible: false,
            loading: false,
            isFullScreen: false,
            /**进度定时 */
            originInterval: null,
            GetInterval: null,
            websocketIns: null,
            /**当前进度 */
            originProgress: 0,
            originSeconds: 40,
            /**状态显示 */
            State: "99",
            currentIng: "",
            originProgressAnimation: false,
            currentRowKey: "",
            FilesData: [],
            pagination: {
                pageNum: 1,
                pageSize: 15,
                total: 0,
            },
            /**报告生成池列表详细数据展示 */
            activities: [],
            innerColumns: [],
            menuList: [
                {
                    title: "全部",
                    name: "00",
                    svg: "alls",
                    value: "200",
                },
                {
                    title: "正在生成",
                    name: "01",
                    svg: "current",
                    value: "11",
                },
                {
                    title: "生成成功",
                    name: "02",
                    svg: "succ",
                    value: "10",
                },
                {
                    title: "生成失败",
                    name: "03",
                    svg: "defeat",
                    value: "20",
                },
            ],
            renderKey: Date.now(),
            columnDrawerTitle: "编辑列",
            successCallback: null,
            deleteCallback: null,
            /** 列项显示与否 抽屉 */
            columnDrawerVisble: false,
            /** 列抽屉展示状态  为true展示ifShow=true的（可删除），为false展示ifShow=false的（可勾选新增） */
            columnShowState: true,
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.loading = true
                // this.progressList()
                this.getWebSocketMessage()
                this.$nextTick(() => {
                    this.$refs.bodyContral.scrollTop = this.$refs.bodyContral.scrollHeight
                    console.log(this.$refs.bodyContral.scrollHeight)
                })
                this.GetFileData()
                this.GetInterval = setInterval(() => {
                    this.GetFileData()
                }, 3000)
            } else {
                this.keyWord = ""
                this.originInterval && clearInterval(this.originInterval)
                this.GetInterval && clearInterval(this.GetInterval)
                this.websocketIns.close()
                this.activities = []
                this.pagination=this.$options.data().pagination
                // this.resetData()
            }
        },
    },
    methods: {
        handleSearch() {
            this.GetFileData()
        },
        /**
         * @description 计算耗时
         * @param {String} start 开始时间
         * @param {String} end   结束时间
         * @returns {Number}     相差秒
         */
        GetTime(start, end) {
            let time = ""
            if (end) {
                let times = new Date(end).getTime() - new Date(start).getTime()
                time = parseInt(times / 1000)
            }
            return time
        },
        handleClick() {
            this.loading = true
            this.activities = []
            this.GetFileData()
        },
        /**报告生成池点击头部tab更改列表展示 */
        handleFileClick(val) {
            this.activeFile = val.name
            this.handleCurrentChange(1)
            // this.GetFileData()
        },
        /**打开报告库 */
        openTemplate(val) {
            this.$FileTask.hide()
            router.push({
                path: "/entrustedmanagement/reportBase",
            })
        },
        /**上传云 */
        UpNect(val) {},
        /**
         * @description 获取数据——报告
         */
        GetFileData() {
            let data = {
                ...this.pagination,
                genType: this.activeFile == "00" ? "" : this.activeFile,
                filters: [
                    {
                        fieldName: "reportGenPool.reportNo",
                        operator: "LIKE",
                        connectOperator: "OR",
                        fieldValue: this.keyWord,
                        groupName: "_s",
                    },
                    {
                        fieldName: "bei.customerName",
                        operator: "LIKE",
                        connectOperator: "OR",
                        fieldValue: this.keyWord,
                        groupName: "_s",
                    },
                    {
                        fieldName: "tsti.taskNo",
                        operator: "LIKE",
                        connectOperator: "OR",
                        fieldValue: this.keyWord,
                        groupName: "_s",
                    },
                    {
                        fieldName: "genType",
                        operator: "LIKE",
                        fieldValue: this.activeFile == "00" ? "" : this.activeFile,
                        groupName: "_s",
                    },
                    {
                        fieldName: "state",
                        operator: "EQ",
                        fieldValue: 0,
                        groupName: "_o",
                    },
                ],
                sorters: [
                    {
                        sortField: "createTime",
                        direction: "DESC",
                    },
                ],
            }
            queryPageReportPool(data).then(res => {
                let arr = res.data.data.records || []
                let i = 0
                if (this.currentRowKey) {
                    arr.forEach((ele, index) => {
                        if (ele.id == this.currentRowKey) {
                            i = index
                            return
                        }
                    })
                }
                this.FilesData = arr
                this.pagination.total = res.data.data.total
                this.handleClickFileRow(this.FilesData[i])
            })
            this.loading = false
        },
        /**
         * @description 获取文件生成
         */
        getWebSocketMessage() {
            this.websocketIns = new WebSocket(WebsocketMessage + `?X-Auth-Token=${getWindowToken()}`)
            this.websocketIns.addEventListener("open", function () {
                console.log("websocket opened")
            })
            this.websocketIns.addEventListener("message", this.handleWebsocketMessage)
            this.websocketIns.addEventListener("close", () => {
                console.log("websocket closed")
            })
        },
        handleWebsocketMessage(event) {
            let data = JSON.parse(event.data)
            this.activities.push({
                content: data.genDescribe || "",
                timestamp: new Date(data.occurrenceTime + 1000 * 60 * 60 * 8).toLocaleString("zh-GB", { timeZone: "UTC" }),
                type: "primary",
            })
            let height = this.$refs.bodyContral.scrollHeight - this.$refs.bodyContral.scrollTop
            if (height <= this.RightHeight - 46) {
                this.$nextTick(() => {
                    this.$refs.bodyContral.scrollTop = this.$refs.bodyContral.scrollHeight
                })
            }
        },
        /**列表点击 */
        handleClickFileRow(row) {
            if (row && row.id) {
                this.currentRowKey = row.id
            } else {
                this.currentRowKey = ""
                // this.activities = []
            }
        },
        handleSizeChange(pageSize) {
            this.pagination.pageSize = pageSize
            this.GetFileData()
        },
        //首页表格第几页
        handleCurrentChange(pageNum) {
            this.pagination.pageNum = pageNum
            this.GetFileData()
        },
        /**下载word */
        Download(url, filename) {
            const link = document.createElement("a")
            link.style.display = "none"
            link.download = filename //跨域文件名无效
            link.href = url
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        /**生成详情清除 */
        ClearActivity() {
            this.activities = []
        },
        // 原始记录/报告  查看
        async handleOriginOrReportView(val) {
            // 原始记录
            if (val.reportType == "01") {
                if (!val.filePath) {
                    MessageBox.alert("原始记录文件不存在", "提示", {
                        confirmButtonText: "确定",
                        type: "warning",
                        showClose: true,
                        customClass: "labMessageClass",
                    })
                } else {
                    let { data } = await getFileUrl({ fileName: val.filePath })
                    this.Download(data, val.reportNo)
                }
            }
            // 报告
            else {
                if (!val.filePath) {
                    MessageBox.alert("报告文件不存在", "提示", {
                        confirmButtonText: "确定",
                        type: "warning",
                        showClose: true,
                        customClass: "labMessageClass",
                    })
                } else {
                    let { data } = await getFileUrl({ fileName: val.filePath })
                    this.Download(data, val.reportNo)
                }
            }
        },
        /**列表进度条 */
        progressList() {
            this.originInterval && clearInterval(this.originInterval)
            this.originProgressAnimation = true
            this.originInterval = setInterval(() => {
                let temp = this.originProgress + parseInt(String(Math.random() * 10))
                let tempSeconds = parseInt(this.originSeconds - (parseInt(Math.random() * 10) % 2 ? 2 : 1))
                if (!(temp < 99)) {
                    this.originProgress = 99
                } else {
                    this.originProgress = temp
                }
                if (tempSeconds < 1) {
                    this.originSeconds = 1
                } else {
                    this.originSeconds = tempSeconds
                }
            }, 3000)
        },
        /**重置页面进度条数据 */
        resetData() {
            this.originProgress = 0
            this.originSeconds = 40
            this.originInterval && clearInterval(this.originInterval)
        },
        /** 编辑列 按钮点击 */
        handleColumnEdit() {
            if (Array.isArray(this.columns)) {
                this.innerColumns = this.columns
                    .filter(item => {
                        return typeof item.type === "undefined" && typeof item.label !== "undefined" && item.label !== "操作"
                    })
                    .map(item => {
                        return {
                            label: item.label,
                            prop: item.prop,
                            ...item,
                            ifShow: typeof item.ifShow === "boolean" ? item.ifShow : true,
                            checked: false,
                            columnHover: false,
                            dropdownLinkVisible: false,
                            dropdownItemVisible: false,
                        }
                    })
            } else {
                this.innerColumns = []
            }
            this.columnDrawerVisble = true
        },
        /**
         * @param { { ifShow: Boolean } } column 列项
         * @description 编辑列抽屉中 当前列是否显示/未显示；columnShowState为true，显示ifShow=true的列；columnShowState为false，显示ifShow=false的列；
         * 需要排除序号、勾选、操作列
         */
        getColumnShowStatusInDrawer(column) {
            let { ifShow } = column
            return (this.columnShowState && ifShow) || (!this.columnShowState && !ifShow)
        },
        /**
         * @description 添加列
         */
        handleColumnAdd() {
            if (this.columnDrawerAddDisabled) return
            this.columnShowState = false
        },
        /**
         * @description 重置
         */
        handleColumnReset() {
            for (let i = 0; i < this.innerColumns.length; i++) {
                this.$set(this.columns[i], "ifShow", true)
                this.innerColumns[i].ifShow = true
                this.innerColumns[i].checked = false
            }
        },
        /**
         * @param {Object} item 列项
         * @param {Boolean} item.columnHover 鼠标移入列项状态
         * @param {Boolean} item.dropdownLinkVisible 下拉link是否显示
         * @description 鼠标移入列项
         */
        handleDrawerColumnHover(item) {
            // console.log('handleDrawerColumnHover');
            item.columnHover = true
            item.dropdownLinkVisible = true
        },
        /**
         * @param {Object} item 列项
         * @param {Boolean} item.columnHover 鼠标移入列项状态
         * @param {Boolean} item.dropdownLinkVisible 下拉link是否显示
         * @description 鼠标移出列项
         */
        handleDrawerColumnLeave(item) {
            // console.log('handleDrawerColumnLeave');
            item.columnHover = false
            if (!item.dropdownItemVisible) {
                item.dropdownLinkVisible = false
            }
        },
        /**
         * @param {Object} item 列项
         * @param {String} item.label 列项label
         * @param {Boolean} item.ifShow 列项是否显示
         * @description 下拉选项点击
         */
        handleDrawerColumnDropdownCommand(item) {
            item.ifShow = false
        },
        /**
         * @param {Boolean} visible 列项对应的下拉选择显示/隐藏
         * @param {Object} item 列项
         * @param {Boolean} item.columnHover 鼠标移入列项状态
         * @param {Boolean} item.dropdownLinkVisible 下拉link是否显示
         * @param {Boolean} item.dropdownItemVisible 下拉选项显示/隐藏
         * @param {String} item.label 列项label
         * @description 下拉选项点击
         */
        handleDrawerColumnDropdownVisibleChange(visible, item) {
            item.dropdownItemVisible = visible
            if (!visible && !item.columnHover) {
                item.dropdownLinkVisible = false
            }
        },
        /**
         * @description 编辑列抽屉 确定
         */
        handleDrawerConfirm() {
            if (!this.columnShowState) {
                this.columnShowState = true
                for (let i = 0; i < this.innerColumns.length; i++) {
                    let ifShow = this.innerColumns[i].ifShow
                    if (ifShow) continue
                    let checked = this.innerColumns[i].checked
                    this.innerColumns[i].ifShow = checked
                    this.innerColumns[i].checked = false
                    this.innerColumns[i].dropdownLinkVisible = false
                    this.innerColumns[i].columnHover = false
                    this.innerColumns[i].dropdownItemVisible = false
                }
                return
            }
            for (let i = 0; i < this.columns.length; i++) {
                let find = this.innerColumns.find(item => this.columns[i].prop && item.prop === this.columns[i].prop)
                if (!find) {
                    continue
                }
                this.$set(this.columns, i, { ...this.columns[i], ifShow: find.ifShow })

                this.renderKey = Date.now()
            }
            this.handleDrawerClose()
        },
        /**
         * @description 编辑列抽屉 取消
         */
        handleDrawerCancel() {
            if (!this.columnShowState) {
                this.columnShowState = true
            } else {
                this.handleDrawerClose()
            }
        },
        /**
         * @description 编辑列抽屉 关闭
         */
        handleDrawerClose() {
            this.columnDrawerVisble = false
        },
        close() {
            this.visible = false
        },
    },
    computed: {
        columns() {
            return [
                {
                    label: undefined,
                    prop: "word",
                    width: "40",
                    ifShow: true,
                },
                {
                    label: "报告编号",
                    prop: "reportNo",
                    ifShow: true,
                },
                {
                    label: "任务编号",
                    prop: "scTaskCode",
                    ifShow: true,
                },
                {
                    label: "委托单位",
                    prop: "customerName",
                    ifShow: true,
                },
                {
                    label: "报告类型",
                    prop: "reportType",
                    width: "80",
                    ifShow: true,
                    options: [
                        {
                            label: "原始记录",
                            value: "01",
                        },
                        {
                            label: "检测报告",
                            value: "02",
                        },
                    ],
                },

                {
                    label: "创建时间",
                    prop: "startTime",
                    ifShow: true,
                    width: "160",
                },
                {
                    label: "耗时(秒)",
                    prop: "timeConsumed",
                    width: "80",
                    ifShow: true,
                    // options: this.filterTag.map(ele => {
                    //     return {
                    //         ...ele,
                    //         value: ele.name,
                    //     }
                    // }),
                    // width: 120,
                },
                {
                    label: "状态",
                    prop: "genType",
                    width: "120",
                    ifShow: true,
                },
                {
                    label: "文件类型",
                    prop: "fileType",
                    width: "100",
                    ifShow: true,
                },
                {
                    label: "文件大小",
                    prop: "fileSize",
                    width: "80",
                    ifShow: true,
                },
                {
                    label: "操作",
                    prop: "action",
                    width: 240,
                    ifShow: true,
                },
            ]
        },
        columnDrawerAddDisabled() {
            return !this.innerColumns.some(item => !item.ifShow)
        },
    },
}
</script>
<style lang="less">
#filereport {
    .filereport-class {
        .el-dialog__header {
            background-color: var(--main-bg-color);
            padding: 10px;
            font-size: 18px;

            color: #fff;
            line-height: 24px;
            position: relative;
            .right_drag {
                position: absolute;
                width: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                top: 12px;
                right: 10px;
                span {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            .el-dialog__title {
                color: #fff;
            }
            .el-dialog__headerbtn {
                top: 10px;
                right: 14px;
                font-size: 20px;
                .el-dialog__close {
                    color: #fff;
                }
            }
        }
        .el-dialog__body {
            padding: 10px 15px;
            height: calc(100% - 47px);
            display: grid;
            background: #fafafa;
        }
        .File_nav {
            width: 160px;
            .menu_item {
                width: calc(100% - 20px);
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .el-menu {
                flex-grow: 1;
                border-radius: 5px;
                border: none;
                overflow: hidden;
                padding-top: 18px;

                .moduleTitle {
                    font-size: 14px;
                    color: #242424;
                    font-weight: bold;
                    padding: 15px 10px;
                    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
                }

                .el-menu-item {
                    height: 42px !important;
                    // padding: 0 !important;
                    // margin: 0 !important;
                    border-radius: 5px !important;
                }

                & .el-menu-item,
                & .el-submenu__title {
                    height: 42px !important;

                    &,
                    & > .submenu__title {
                        color: #242424;
                        height: 50px;
                        margin: 4px 0;
                        font-size: 12px;
                        font-weight: unset;
                        border-radius: 4px;
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                    }

                    & svg {
                        flex-shrink: 0;
                        margin: 0 11px;
                        width: 16px;
                        height: 16px;
                    }

                    &:focus {
                        background-color: unset;
                    }

                    position: relative;

                    &.is-active,
                    &:hover {
                        background-color: #f0f0f0 !important;
                        border-radius: 5px;
                        // & svg {
                        //   & path,
                        //   & tspan {
                        //     fill: #00796a !important;
                        //   }
                        // }
                        // & span {
                        //   color: #242424;
                        // }
                    }
                }
            }
        }
        .file_body_task {
            display: grid;
            grid-template-rows: 1fr auto;
            border-radius: 4px;
            overflow: hidden;
            // height: 100%;
            position: relative;
            grid-row-gap: 10px;
            row-gap: 10px;
            .color-green {
                color: #008024;
            }
            .color-red {
                color: red;
            }
            .file_left {
                overflow: hidden;
                background: #fff;
                border: 1px solid #d8d8d8;
                padding: 10px;
                .file_refresh {
                    padding: 10px 0 10px 10px;
                    color: #333;
                    height: 100%;
                    margin-left: 0;
                    & > span {
                        height: 20px;
                        width: 100%;
                        display: inline-flex;
                        padding-right: 20px;
                        align-items: center;
                        // border-right: 1px solid #e5e5e4;

                        & > img,
                        & > svg {
                            width: 16px;
                            height: 16px;
                            margin: 0 8px 0 0;
                        }
                    }
                }
                .el-tabs {
                    & > .el-tabs__header {
                        margin: 0;

                        & > .el-tabs__nav-wrap {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                .lab-table {
                    height: 100%;
                    .lab-table__body {
                        margin-top: 10px;
                    }
                }
                .edit-column,
                .filter-column {
                    align-self: stretch;
                    display: flex;
                    align-items: center;
                    margin: 0 18px 0 0;
                    white-space: nowrap;
                    cursor: pointer;

                    & > img {
                        width: 16px;
                        height: 16px;
                        margin: 0 8px 0 0;
                    }
                }
                .default-input {
                    width: 200px;
                }
                .parent_bar {
                    position: relative;
                    width: 100%;
                    height: 23px;
                    .span {
                        position: absolute;
                        right: 0;
                        top: 0;
                        &.white {
                            color: #fff;
                            // right: 10%;
                        }
                    }
                    .origin-progress-bar,
                    .report-progress-bar {
                        display: flex;
                        // align-items: center;
                        position: relative;
                        // margin: 10px 0 0;

                        height: 100%;
                        background-color: #008024;
                        &.animation {
                            transition: width 1.8s ease-in-out;
                        }

                        // &>.origin-progress-number,
                        // &>.report-progress-number {
                        //     // position: absolute;
                        //     // right: -78px;
                        //     width: 230px;
                        //     // top: -25px;
                        //     // padding: 0 0 0 10px;
                        //     margin: -15px 0 0;
                        //     font-size: 12px;
                        // }
                    }
                }
            }
            .file_right {
                padding-top: 10px;
                display: grid;
                padding: 10px;
                grid-template-rows: auto 1fr;
                border-radius: 4px;
                overflow: hidden;
                background: #fff;
                border: 1px solid #d8d8d8;
                .table_header {
                    display: flex;
                    justify-content: space-between;
                    background: rgb(248, 248, 248);
                    color: rgb(51, 51, 51);
                    padding: 10px 10px 9px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    overflow: hidden;
                    border-bottom: 1px solid #ebeef5;
                    span {
                        cursor: pointer;
                    }
                }
                .table_body {
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding: 10px 10px 42px;
                    .el-timeline-item:last-child .el-timeline-item__tail {
                        display: none;
                    }
                    // .el-timeline-item__content {
                    //     width: 172px;
                    //     overflow: hidden;
                    //     text-overflow: ellipsis;
                    //     white-space: nowrap;
                    // }
                    .el-timeline-item {
                        list-style-type: none;
                    }
                }
                // width: 100px;
            }
        }
    }
    .columnDrawer {
        & .column-header {
            border-bottom: 1px solid #666;
            padding: 0 0 7px;
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            & > div {
                cursor: pointer;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                & > svg,
                & > i {
                    margin: 0 10px 0 0;
                }
                &.is-disabled {
                    cursor: not-allowed;
                    color: #bbb;
                }
                &.column-add {
                }
                &.column-reset {
                    margin: 0 0 0 30px;
                }
            }
        }
        & .column-body {
            padding: 8px 0 20px;
            display: flex;
            flex-flow: column nowrap;
            & > .column-item {
                margin: 12px 0 0;
                padding: 10px 10px;
                border-radius: 2px;
                background-color: rgb(242, 242, 242);
                white-space: nowrap;
                display: flex;
                flex-flow: row nowrap;
                & > .column-item--checkbox {
                    flex-shrink: 0;
                    width: 14px;
                }
                & > .column-item--label {
                    flex-grow: 1;
                    padding: 0 6px 0 8px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                & > .el-dropdown {
                    flex-shrink: 0;
                    width: 16px;
                    & > .el-dropdown-link {
                        color: #333;
                    }
                }
                &:hover {
                    & .el-dropdown-link {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .firle {
        width: 70%;
        height: 80%;
    }
}
</style>
