<template>
    <el-date-picker v-model="value" v-bind="getDatePickerProps"></el-date-picker>
</template>

<script>
import {disabledDate} from "./hook.js"
export default {
    name: "DatePicker",
    model: {
        prop: "modelValue",
        event: "change",
    },
    props: {
        /* public */
        modelValue: {
            type: String,
            default: "",
        },
        startDate: {
            type: String,
            default: "",
        },
        endDate: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
           
        }
    },
    mounted() {},
    methods:{
    }
    ,
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit("change", value)
            },
        },
        getDisabledDate(){
            return disabledDate(this.value)
        },
        getDatePickerProps() {
            let attr = {
                /** 可输入？ */
                editable: false,
                /** 可清楚？ */
                clearable: false,
                placeholder: "请输入",
                /** 类型 */
                type: "date",
                /** 格式 */
                valueFormat: "yyyy-MM-dd",
                ...this.$attrs,
            }
            return attr
        },
    },
    methods: {},
}
</script>

<style></style>
