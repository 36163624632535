// const devServer = 'http://192.168.23.10:26570';
const devServer = ""
// const devServer = 'http://47.104.68.37:13012';
// const devServer = 'http://192.168.23.26:26579';
/**本地运行地址 */
/**服务器地址 */
// const Server = "//118.178.184.218:"
// const Server = "//47.104.68.37:"
const Server = "//" + window.location.hostname + ":"
/** iframe */
// async function getIP() {  
//     try {  
//       const response = await fetch('https://labbolwuhan.coding.net/p/xiaolechanpin/d/labbol-basic-components/git');  
//       const data = await response.json();  
//       return data.query;  
//     } catch (error) {  
//       console.error('Error:', error);  
//     }  
//   }  
  
//   getIP().then(ip => console.log("ip",ip));  

//模板库
const ifrServer=process.env.NODE_ENV === "development" ?  "http://47.104.68.37:13015" :  `http:${Server}13015`
//退出登录 小乐云 跳转后台租户
// const ifrServerLogout=process.env.NODE_ENV === "development" ? "http://192.168.23.34:8080/" : `http:${Server}13016/`;
const ifrServerLogout=process.env.NODE_ENV === "development" ? "http://47.104.68.37:13016/" : `http:${Server}13016/`
const ssoServer = devServer
/** 生成地址 开发环境为/api代理  生产环境为本地localhost */
/**报告生成 文件生成是否启动*/
const localGenerateBaseUrl = process.env.NODE_ENV === "development" ? "/WebSocktask" : "http://localhost:23489"
// const loginServer = process.env.NODE_ENV === "development" ? devServer : `http:${Server}13016`
// const Org = process.env.NODE_ENV === "development" ? devServer : `http:${Server}13017`
const loginServer = devServer
/**检测人员 */
const Org = devServer
/**报告生成 获取文件生成信息*/
const Websocket = process.env.NODE_ENV === "development" ? "ws://192.168.23.26:23489/webSocket" : "ws://localhost:23489/webSocket"
/**报告生成池信息获取 */
const WebsocketMessage = process.env.NODE_ENV === "development" ? "ws://192.168.23.26:26571/websocket" : `ws:${Server}13012/v1.0/resource/websocket`
export default {
    ssoServer,
    loginServer,
    Org,
}

export { ssoServer, localGenerateBaseUrl, loginServer, Org, Websocket, WebsocketMessage, ifrServer,ifrServerLogout }
