// import http from '../utils/http'
import http from "./http"
import global_ from "../assets/js/Global"

// let tenant = global_.ssoServer + '/v1.0/tenant';
// let resourceBaseUrl = global_.ssoServer + '/v1.0/resource/';
let base = global_.ssoServer
let tenant = global_.ssoServer + "/v1.0/tenant"
let resourceBaseUrl = global_.ssoServer + "/v1.0/resource/"
/**自定义表单 */
let customform = global_.ssoServer + "/v1.0/customform/form/"
// let dataset = global_.ssoServer + '/v1.0/dataset/dataset/'; //数据集-数据集
let connect = global_.ssoServer + "/v1.0/connect/connect/" //连接-数据集
let template = global_.ssoServer + "/v1.0/templateManage/" //模板库
let loginServer = global_.loginServer + "/unifyauth"
/**检测 */
let Org = global_.Org + "/unifyauth"
export default class api {
    //==========↓==========↓==========↓==========↓==========GET==========↓==========↓==========↓==========↓==========

    /**
     *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
     *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
     */
    static getListAPI(params, url, config) {
        return http.get(params, `${base}${url}`, config)
    }
    static getLoginAPI(params, url, config) {
        return http.get(params, `${loginServer}${url}`, config)
    }
    static getOrgAPI(params, url, config) {
        return http.get(params, `${Org}${url}`, config)
    }
    static getListAPIUnifyAuth(params, url, config) {
        return http.get(params, `${Org}${url}`, config)
    }
    static getListAPItenant(params, url, config) {
        return http.get(params, `${tenant}${url}`, config)
    }
    static getListAPIResource(params, url, config) {
        return http.get(params, `${resourceBaseUrl}${url}`, config)
    }
    static getListAPICustomForm(params, url, config) {
        return http.get(params, `${customform}${url}`, config)
    }
    static getListAPITemplate(params, url, config) {
        return http.get(params, `${template}${url}`, config)
    }
    static getListAPIConnect(params, url, config) {
        return http.get(params, `${connect}${url}`, config)
    }

    //==========↑==========↑==========↑==========↑==========GET==========↓==========↓==========↓==========↓==========

    //==========↓==========↓==========↓==========↓==========POST==========↓==========↓==========↓==========↓==========
    static postFormAPI(params, url, urlData, config) {
        return http.post(params, `${base}${url}`, urlData, config)
    }
    static postLoginAPI(params, url, urlData, config) {
        return http.post(params, `${loginServer}${url}`, urlData, config)
    }
    static postOrgAPI(params, url, urlData, config) {
        return http.post(params, `${Org}${url}`, urlData, config)
    }
    static postFormAPIUnifyAuth(params, url, urlData, config) {
        return http.post(params, `${Org}${url}`, urlData, config)
    }
    static postFormAPItenant(params, url, urlData, config) {
        return http.post(params, `${tenant}${url}`, urlData, config)
    }
    static postFormAPIResource(params, url, urlData, config) {
        return http.post(params, `${resourceBaseUrl}${url}`, urlData, config)
    }
    static postFormAPICustomForm(params, url, urlData, config) {
        return http.post(params, `${customform}${url}`, urlData, config)
    }
    static postFormAPITemplate(params, url, urlData, config) {
        return http.post(params, `${template}${url}`, urlData, config)
    }
    static postFormAPIConnect(params, url, urlData, config) {
        return http.post(params, `${connect}${url}`, urlData, config)
    }
    //==========↑==========↑==========↑==========↑==========POST==========↓==========↓==========↓==========↓==========

    //==========↓==========↓==========↓==========↓==========PUT==========↓==========↓==========↓==========↓==========

    static putFormAPItenant(params, url, urlData, config) {
        return http.put(params, `${tenant}${url}`, urlData, config)
    }
}

export { tenant }
