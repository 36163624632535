<template>
    <Form v-bind="formProps" :model="model" :class="getClass" ref="FormRef">
        <el-row style="width: 100%;"  v-bind="useFormItemRowProp(formProps, $attrs)" >
            <!-- <el-col v-bind="useFormColProp($attrs)" style="display: flex; flexWrap: wrap"> -->
                <el-col v-for="schema in getSchemas" :key="schema.prop" v-bind="useFormItemColProp(schema, $attrs)">
                    <BasicFormItem v-if="useComponentName(schema)" v-bind="useFormItemProp(schema, $props)"  :key="schema.key">
                        <template v-for="slot in execSlotNames(schema)">
                            <template v-if="!slot.default" :slot="slot.target">
                                <slot :name="slot.origin"></slot>
                            </template>
                            <template v-else>
                                <slot :name="slot.origin"></slot>
                            </template>
                        </template>
                    </BasicFormItem>
                </el-col>
            <!-- </el-col> -->
            <!-- <el-col v-if="useActionColProp($attrs)" v-bind="useActionColProp($attrs)" class="action-col">
                <BasicFormItem class="form-item-action">
                    <Button v-for="buttonOption in useActionColProp($attrs).buttonOptions" v-bind="buttonOption" :key="buttonOption.key" @click="handleActionButtonClick(buttonOption)"></Button>
                </BasicFormItem>
            </el-col> -->
        </el-row>
    </Form>
</template>

<script>
import { Form, Button } from 'element-ui';
import BasicFormItem from './BasicFormItem.vue';
// import Button from '../../Button';
import { prefixCls } from './constant';
import { useComponentName, useFormProp, useFormItemProp, useFormColProp, useActionColProp, useFormItemColProp,useFormItemRowProp } from './hooks';
import { useColProp } from '../../hooks';
import { getUuid } from '../../utils';
export default {
    name: 'BasicForm',
    components: {
        Form, BasicFormItem, Button,
    },
    provide(){
        return {
            // model: this.$attrs.model,
            getModel: () => this.model,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onChange: this.onChange,
            onInput: this.onInput,
            onClear: this.onClear,
            // getSchemas: () => this.getSchemas,
        }
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        },
        // labelPosition: {
        //     type: String,
        //     default: 'top',
        // },
        labelSuffix: {
            type: String,
            default: '',
        },
        schemas: {
            type: Array,
            default: () => {
                return []
            }
        },
        showActionCol: {
            type: Boolean,
            default: true,
        }
    },
    data(){
        return {
            // model: {},
        }
    },
    mounted(){
        // console.log('BasicForm', this.$attrs, this.labelPosition);
    },
    computed: {
        getClass(){
            return prefixCls;
        },
        formProps(){
            let formProps = useFormProp(this.$attrs);
            let { model } = formProps;
            console.log('BasicForm.computed.formProps.model', model);
            delete formProps.model;
            return formProps;
        },
        getSchemas(){
            // console.log('getSchemas', this.schemas);
            return this.schemas.map(item => {
                return {
                    key: getUuid(32, 36),
                    ...item,
                }
            })
        },
        innerModel(){
            return this.$attrs.model;
        }
    },
    watch: {
        schemas: {
            handler(schemas){
                console.log('BasicForm.watch.props.schemas', schemas);
            },
            immediate: true,
        },
        getSchemas: {
            handler(schemas){
                console.log('getSchemas', schemas);
                // this.setDefaultModel();
            },
            immediate: true,
        },
        formProps:{
            handler(val){
                console.log('watch.formProps', val);
            },
            immediate: true
        },
        innerModel: {
            handler(val){
                console.log('innerModel', val);
            },
            immediate: false,
            deep: true,
        }
    },
    methods: {
        useComponentName, useFormItemProp, useFormColProp, useActionColProp, useColProp, useFormItemColProp,useFormItemRowProp,
        execSlotNames(schema){
            let { prop } = schema;
            let reg = new RegExp(`^${ prop }((-[a-z]+)*)`);
            // console.log(prop, reg, );
            let slotNames = Object.keys(this.$slots);
            let targetSlotNames = [];
            for (let i = 0; i < slotNames.length; i++){
                let execArray = reg.exec(slotNames[i])
                if (!execArray) continue;
                // console.log(execArray);
                targetSlotNames.push({
                    origin: slotNames[i],
                    default: !execArray[1],
                    target: execArray[1] ? execArray[1].slice(1) : 'default',
                });
            }
            // console.log('execSlotNames', targetSlotNames);
            return targetSlotNames;
        },
        setDefaultModel(){
            // for (let i = 0; i < this.schemas.length; i++){
            //     let { prop } = this.schemas[i];
            //     this.model[prop] = this.model[prop] || '';
            // }
        },
        
        onFocus(prop, e){
            this.$emit('focus', prop, e);
        },
        onBlur(prop, e){
            this.$emit('blur', prop, e);
        },
        /**
         * @param {String} prop 属性名称
         * @param {String|Number} value 属性值
         * @description 设置属性值
        */
        setFieldValue(prop, value){
            this.$set(this.innerModel, prop, value);
            this.$nextTick(() => {
                this.validateField(prop);
            })
        },
        onChange(value, prop, schema){
            // console.log('onChange', value, prop, schema);
            this.validateField(prop);
            this.$emit('change', value, prop, schema);
            let { componentProps } = schema;
            if (typeof componentProps !== 'object' || !componentProps) return;
            let { onChange } = componentProps;
            if (typeof onChange === 'function'){
                // console.log('BasicForm.onChange.componentProps.onChange', onChange);
                onChange({
                    value, prop, schema, model: this.innerModel, setFieldValue: this.setFieldValue
                })
            }
        },
        onInput(value, prop){
            console.log('BasicForm.onInput', value, prop);
            this.$emit('input', value, prop);
        },
        onClear(prop){
            this.$emit('clear', prop);
        },
        handleActionButtonClick(buttonOption){
            // console.log('handleActionButtonClick', buttonOption);
            let { name } = buttonOption;
            if (name === 'search'){
                console.log('handleActionButtonClick', 'search');
                this.$emit('search');
            }
             else if (name === 'reset'){
                this.$refs.FormRef.resetFields();
                console.log('handleActionButtonClick', 'reset');
                this.$emit('reset');
             }
        },
        validate(callback){
            return new Promise(resolve => {
                this.$refs.FormRef.validate(valid => {
                    if (typeof callback === 'function'){
                        callback(valid);
                    }
                    resolve(valid);
                });
            })
        },
        /**
         * @description 表单校验
        */
        validateField(...args){
            return this.$refs.FormRef.validateField(...args);
        },
        /**
         * @description 表单重置、清除校验
        */
        resetFields(){
            this.$refs.FormRef.resetFields();
        },
        clearValidate(props){
            this.$refs.FormRef.clearValidate(props);
        },
    }
}
</script>
<style>
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    margin-right: 0;
}
</style>