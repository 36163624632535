<template>
	<BasicDrawer :visible="innerVisible" ref="UploadDrawerRef" :formConfig="formConfig" :model="formData"
		@confirm="handleConfirm" @close="handleClose" title="文件上传">
		<template slot="file">
			<el-upload action="/" :multiple="false" :http-request="handleUpload" :file-list="fileList"
				accept=".xls, .xlsx" :before-upload="beforeUpload">
				<el-button style="background-color: #0a8a55; color: white" size="small">点击上传</el-button>
			</el-upload>
		</template>
	</BasicDrawer>
</template>

<script>
	export default {
		name: 'BasicDrawerUpload',
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				formConfig: {
					labelPosition: "left",
					labelWidth: "180px",
					size: "small",
					schemas: [{
						label: "上传文件",
						prop: "file",
						rules: [{
							required: true,
							message: "请上传文件"
						}],
					}, ],
				},
				formData: {
					file: '',
				},
				fileList: [],
			}
		},
		computed: {
			innerVisible: {
				get() {
					return this.visible
				}
			}
		},
		watch: {
			innerVisible: {
				handler(val){
					if (val){
						this.fileList = [];
					}
				},
				immediate: false,
			}
		},
		methods: {
			beforeUpload(file) {
				let reg = /\.xlsx?$/;
				let flag = reg.test(file.name);
				if (!flag){
					this.$message({
						type: 'warning',
						message: '请上传xls、xlsx文件！'
					});
				}
				return flag;
			},
			handleUpload(params) {
				this.formData.file = params.file
				this.$refs.UploadDrawerRef.validate("file");
			},
			handleConfirm() {
				this.$emit('confirm', this.formData);
			},
			handleClose() {
				this.$emit('close');
			}
		}
	}
</script>

<style>

</style>