<template>
	<div id="context-menu-list" v-if="visible" :style="position" @click.stop>
		<div class="context-menu-list--item" v-for="(item, index) in contextMenuList" :key="index"
			@click="handleClick(item, index)">
			<svg-icon :icon-class="item.svg" />
			<span>{{ item.text }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ContextMenuList',
		data() {
			return {
				/** 右键菜单 显示状态 */
				visible: false,
				contextMenuList: [],
				position: {
					top: 0,
					left: 0,
				}
			}
		},
		methods: {
			/** 显示右键菜单 */
			open(options) {
				let args = options || {};
				for (let key in args){
					this[key] = args[key]
				};
				console.log('open', options);
				this.visible = true;
				window.addEventListener('click', this.close);
			},
			/** 关闭右键菜单 */
			close(e){
				console.log('close', e);
				this.visible = false;
				window.removeEventListener('click', this.close);
			},
			/** 监听右键菜单面板之外的点击事件 进行面板关闭 */
			// addCloseListener(){

			// },
			menuItemClick(...args){
				// console.log('menuItemClick', ...args);
			},
			handleClick(item, index) {
				if (typeof this.menuItemClick === 'function') {
					this.menuItemClick(item, index);
				}
				if (typeof item.onClick === 'function' && item.onClick){
					item.onClick(item, index);
				}
				this.close();
			},

		}

	}
</script>

<style lang="less">
	#context-menu-list {
		position: fixed;
		padding: 6px 0px;
		box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.6);
		border-radius: 5px;
		// border: 1px solid rgba(0, 0, 0, 0.1);
		background-color: #fff;
		&>.context-menu-list--item{
			font-size: 12px;
			color: #333;
			padding: 5px 16px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			cursor: pointer;
			&:hover{
				background-color: #f0f0f0;
			}
			&>svg{
				margin: 0 6px 0 0;
				width: 16px;
				height: 16px;
			}
		}
	}
</style>