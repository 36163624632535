<template>
    <div :class="getWrapperClass">
        <Select v-bind="getSelectProps" v-model="value" ref="SelectRef" @focus="onFocus" @blur="onBlur" @clear="onClear">
            <Option v-for="item in getOptions" :key="item.index" v-bind="item" />
        </Select>
        <div :class="getAppendClass">
            <slot name="append">{{ $attrs.append }}</slot>
        </div>
    </div>
    
</template>

<script>
import { Select, Option } from 'element-ui';
import { prefixCls } from './constant';
import { getUuid } from '../../utils';
import { useSelectProps } from './hooks';
export default {
    name: 'BasicSelect',
    components: {
        Select, Option,
    },
    model: {
        prop: "modelValue",
        event: "change",
    },
    props: {
        /* public */
        modelValue: {
            type: String|Array,
            default: "",
        },
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {
                    label: 'label',
                    value: 'value',
                }
            }
        }
    },
    mounted(){
        console.log('basic-select', this.$attrs, this.modelValue);
    },
    computed: {
        slotKeys(){
            return Object.keys(this.$slots);
        },
        getClass(){
            return prefixCls;
        },
        getWrapperClass(){
            return [this.getClass, this.slotKeys.includes('append') ? 'has-append' : ''];
        },
        getAppendClass(){
            return `${ this.getClass }__append`
        },
        getSelectProps(){
            return useSelectProps(this.$attrs);
        },
        getOptions(){
            console.log('selectoptions', this.options);
            return this.options.map(item => {
                return {
                    ...item,
                    index: getUuid(32, 36),
                    label: item[this.defaultProps.label],
                    value: item[this.defaultProps.value],
                    style: {},
                }
            })
        },
        value: {
            get(){
                return this.modelValue;
            },
            set(value){
                this.$emit('change', value, this.options.find(item => item[this.defaultProps.value] === value));
            }
        }
    },
    methods: {
        onBlur(e){
            this.$emit('blur', e);
        },
        onFocus(e){
            this.$emit('focus', e);
        },
        onClear(e){
            this.$emit('blur', e);
        },
        focus(){
            this.$refs.SelectRef.focus();
        },
        blur(){
            this.$refs.SelectRef.blur();
        },
    }
}
</script>

<style>

</style>