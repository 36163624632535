<template>
    <div id="setfile">
        <el-dialog
            v-dialogDrag
            :custom-class="isFullScreen ? 'quens view_dialog_class' : 'view_dialog_class'"
            :fullscreen="isFullScreen"
            :showClose="false"
            :title="title"
            :visible.sync="visible"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
        >
            <template slot="title">
                <div @dblclick="isFullScreen = !isFullScreen">
                    <span>{{ title }}</span>
                    <div class="right_drag">
                        <span @click="isFullScreen = !isFullScreen">
                            <svg-icon style="font-size: 16px" :icon-class="isFullScreen ? 'quee' : 'queen'"></svg-icon>
                        </span>
                        <span @click="onclose">
                            <img src="@/assets/img/modal/deletedig.png" alt="" />
                        </span>
                    </div>
                </div>
            </template>
            <div v-if="isFullScreen" class="dialog_footer">
                <el-button v-if="title != '查看'" @click="SaveExcel">确定</el-button>
                <el-button @click="onclose">取 消</el-button>
            </div>
            <iframe :src="viewUrl" id="templateIfr" frameborder="0"></iframe>
            <div v-if="!isFullScreen" :slot="'footer'" class="dialog-footer">
                <el-button v-if="title != '查看'" @click="SaveExcel">确定</el-button>
                <el-button @click="onclose">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Button, Dialog } from "element-ui"
import { ifrServer } from "@/assets/js/Global"
import { checkFileExistence } from "@/api/main"
import SvgIcon from "@/components/SvgIcon"
export default {
    name: "choiceObject",
    components: {
        "el-button": Button,
        "el-dialog": Dialog,
        "svg-icon": SvgIcon,
    },
    data() {
        return {
            url: "", //禁用选择id或realName 数组
            title: "查看",
            isFullScreen: false,
            viewUrl: "",
            visible: false,
            isOriginal: false,
            path: "",
            successCallback: null,
            deleteCallback: null,
        }
    },
    watch: {
        url(val) {
            val && this.openFileIIs(val)
        },
    },

    methods: {
        async openFileIIs(filename) {
            this.path = filename
            if (filename) {
                const res = await checkFileExistence({ fileName: filename })
                if (res.data.data) {
                    this.visible = true
                    if (this.isOriginal) {
                        this.viewUrl = filename
                    } else {
                        this.viewUrl = `${ifrServer}/template/excelViewer?path=${filename}&templateId=123`
                    }
                    window.addEventListener(
                        "message",
                        event => {
                            //父获取子传递的消息
                            if (event.origin === ifrServer && event.data && event.data == "close") {
                                this.onclose()
                            }
                        },
                        false
                    )
                } else {
                    this.onclose()
                    this.$message({
                        message: "当前文件不存在",
                        type: "error",
                    })
                }
            }
        },
        //保存
        SaveExcel() {
            const iframeObj = document.querySelector("#templateIfr")
            let data = JSON.stringify({
                action: "saveExcel",
                message: "",
            })
            let url = ifrServer
            iframeObj.contentWindow.postMessage(data, url)
            if (typeof this.successCallback === "function") {
                this.successCallback(data)
            }
        },
        onclose() {
            this.visible = false
            this.isFullScreen = false
            let deleteurl = this.viewUrl
            this.viewUrl = ""
            this.url = ""
            if (!this.isOriginal) {
                this.api.getListAPI({ path: this.path }, `${ifrServer}/template/delete`, "")
            } else {
                if (typeof this.deleteCallback === "function") {
                    this.deleteCallback(deleteurl, this.path)
                }
            }
        },
    },
}
</script>
<style lang="less">
#setfile {
    .view_dialog_class {
        min-height: 500px;
        .el-dialog__header {
            background-color: var(--main-bg-color) !important;
            padding: 10px !important;
            color: #fff !important;
            font-size: 18px !important;
            line-height: 24px !important;
        }
        .el-dialog__body {
            padding: 0;
            > iframe {
                width: 100%;
                min-height: 500px;
            }
        }
        .el-dialog__footer {
            padding: 10px 20px;
        }
        .dialog_footer {
            display: flex;
            justify-content: flex-end;
            padding: 10px 20px 0 0;
        }
    }
    .view_dialog_class .el-dialog__header {
        background-color: var(--main-bg-color);
        padding: 10px;
        color: #fff;
        font-size: 18px;
        line-height: 24px;
        position: relative;
        .right_drag {
            position: absolute;
            width: 60px;
            display: flex;
            justify-content: space-between;
            top: 12px;
            right: 10px;
            span {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
    .quens {
        top: 0 !important;
        left: 0 !important;
        .el-dialog__body {
            padding: 0;
            > iframe {
                width: 100%;
                height: 88vh;
            }
        }
    }
}
</style>
