import contextMenu from './index.vue';
export default contextMenu;
// //index.js文件
// import Vue from 'vue'
// import cropperImage from './index.vue';

// const $app = Vue.extend(cropperImage);

// const $cropperImage = new $app().$mount(document.createElement('div'))


// document.body.appendChild($cropperImage.$el)

// let cropperImageFunction = function (options) {
//     // console.log('cropperImageFunction', this);
//     this.open({
//         ...options || {}
//     });
// }
// cropperImageFunction = cropperImageFunction.bind($cropperImage);
// // .bind($cropperImage);

// // cropperImageFunction.preview = function (options) {
// //     this.options = {
// //         ...options,
// //         mode: 'preview'
// //     };
// // }


// export default {
//     install(vm) {
//         vm.prototype.$cropperImage = cropperImageFunction;
//     }
//     // /$cropperImage.visible = false
// }