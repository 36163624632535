const isValidArray = (value) => {
    return !!(Array.isArray(value) && value.length);
}
const isValidValueNumber = (value) => {
    let reg = /^\d+(\.\d+)?$/;
    return !!(typeof value === 'number' || reg.test(value));
}
// 验证文件
/**
 * @description 验证文件
 * @param {*} file 后端返回的二进制文件流
 * @return {*} true | Error
 */
let verifyFile = (file) => {
    return new Promise(resolve => {
        // console.log('file', file);
        if (file instanceof Blob && file.size == 0) {
            labMessageWarn('文件为空', 'warning');
            throw new Error('文件为空');
        }
        file.text()
            .then(res => {
                // console.log('res', res);
                let obj = JSON.parse(res);
                if ((typeof obj === 'object' && obj.success === false)) {
                    // console.log('success === false');
                    obj.msg && labMessageWarn(obj.msg, 'warning');
                    throw new Error('文件不存在');
                } else {
                    resolve(true);
                }
            })
            .catch(error => {
                // console.log('catch', error.message);
                if (typeof error.message === 'string' && error.message === '文件不存在') {
                    // console.log('error', typeof error);
                    throw new Error('文件不存在');
                } else {
                    resolve(true);
                }
            })
    });
}

/**
 * @description 下载文件方法
 * @param {*} fileName 文件名 必须要有文件类型后缀
 * @param {*} file 后端返回的二进制文件流
 * @param {*} url 下载文件的地址 base64或者blob地址
 */
let downloadFile = async (fileName, file, url) => {
    let href;
    let blob;
    let base64Reg = /^data:image\/([a-z]+);base64/i;
    let blobReg = /^blob:https?:/;
    if (file instanceof Blob) {
        await verifyFile(file);
        blob = new Blob([file]);
        href = window.URL.createObjectURL(blob);
    } else if (blobReg.test(url)) {
        href = url;
    } else if (base64Reg.test(url)) {
        blob = base64ToBlob(url);
        console.log('fileName', fileName);
        href = window.URL.createObjectURL(blob);
    }
    let link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    link.style.display = 'none';
    console.log('downloadFile', href);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
}

/**
 * @description 预览文件方法
 * @param {*} fileType 文件类型 必传
 * @param {*} file 后端返回的二进制文件流
 */
let previewFile = async (fileType, file, defaultOpen) => {
    await verifyFile(file);

    let type = '';
    switch (fileType) {
        case 'pdf':
            type = 'application/pdf;charset-UTF-8';
            break;
        case 'doc':
            type = 'application/msword;charset-UTF-8';
            break;
        case 'docx':
            type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset-UTF-8';
            break;
        case 'xlsx':
            type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
            break;
        case 'xls':
            type = 'application/vnd.ms-excel;charset-UTF-8';
            break;
        case 'jpg':
            type = 'image/jpeg;charset-UTF-8';
            break;
        case 'png':
            type = 'image/png;charset-UTF-8';
            break;
        case 'gif':
            type = 'image/gif;charset-UTF-8';
            break;
        case 'webp':
            type = 'image/webp;charset-UTF-8';
            break;
        default:
            type = 'application/pdf;charset-UTF-8';
            break;
    }
    let url = window.URL.createObjectURL(new Blob([file], {
        type
    }));
    console.log('url', url);
    defaultOpen && window.open(url, '_blank');
    return url;
}

/**
 * @description base64转blob
 */
const base64ToBlob = (base64) => {
    var parts = base64.split(';base64,');
    var contentType = parts[0].split(':')[1];
    console.log('base64ToBlob', contentType);
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {
        type: contentType
    });
}

/**
 * Base64字符串转File文件
 * @param {String} base64 Base64字符串(字符串包含Data URI scheme，例如：data:image/png;base64, )
 * @param {String} filename 文件名称
 */
let base64toFile = (base64, filename) => {
    let arr = base64.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = window.atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
        type: mime
    });
}
/**
 * Blob转Base64字符串
 * @param {Blob} blob Blob
 * @return base64
 */
const blobToBase64 = (blob, type) => {
    return new Promise(async resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(new Blob([blob], {
            type: type || 'image/jpg'
        }));
        reader.onload = e => {
            resolve(e.target.result);
        }
        reader.onerror = () => {
            resolve('');
        }
    })
}


/**
 * @description 生成uuid
 * @param {*} len 
 * @param {*} radix 
 * @returns uuid
 */
let getUuid = (len, radix) => {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    let uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        var r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}

let getCurrentDate = () => {
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    month < 10 && (month = '0' + month);
    let date = new Date().getDate();
    date < 10 && (date = '0' + date);
    return year + '-' + month + '-' + date;
}
export {
    downloadFile,
    previewFile,
    verifyFile,
    base64toFile,
    blobToBase64,
    getUuid,
    getCurrentDate,
}