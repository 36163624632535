<template>
    <Pagination :class="getClass" v-bind="getPaginationProps" @size-change="sizeChange" @current-change="currentChange" />
</template>

<script>
import { prefixCls } from './constant';
import { Pagination } from 'element-ui';
import { usePaginationProps } from './hooks';
export default {
    name: 'LabPagination',
    components: {
        Pagination,
    },
    data(){
        return {

        }
    },
    computed: {
        getClass(){
            return prefixCls;
        },
        getPaginationProps(){
            return usePaginationProps(this.$attrs);
        }
    },
    methods: {
        sizeChange(pageSize){
            // console.log('sizeChange', pageSize);
            this.$emit('size-change', pageSize);
        },
        currentChange(pageNum){
            // console.log('currentChange', pageNum);
            this.$emit('current-change', pageNum);
        },
    }
}
</script>

<style lang="less">
    @preCls: lab-pagination;
    .@{preCls}{
        & *{
            font-size: 14px!important;
        }
    }
</style>