<template>
    <el-autocomplete style="width: 100%" :placeholder="placeholder" v-model="value" :fetch-suggestions="queryCustomerName" @select="selectCustomName" :value-key="key"></el-autocomplete>
</template>

<script>
import { queryClient } from "@/api/main"
export default {
    name: "BSelect",
    model: {
        prop: "modelValue",
        event: "change",
    },
    data() {
        return {
            key: this.modelKey,
        }
    },
    props: {
        /* public */
        modelValue: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            default: () => {
                return []
            },
        },
        placeholder: {
            type: String,
            default: () => {
                return "请输入"
            },
        },
        modelKey: {
            type: String,
            default: "customerName",
        },
        QueryData: {
            type: Function,
            default: null,
        },
    },
    mounted() {
        // console.log('basic-input', this.$attrs, this.modelValue);
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit("change", value)
            },
        },
    },
    methods: {
        selectCustomName(e) {},
        async queryCustomerName(queryString, cb) {
            if (queryString) {
                const res = await (this.QueryData ? this.QueryData(queryString) : queryClient(queryString))
                cb(res.data.data)
            } else {
                cb([])
            }
        },
    },
}
</script>

<style></style>
